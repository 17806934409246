import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionHeader } from "./section-header";

// api
import api from "../../utils/api";

// icons
import { BsFillPersonFill } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";

// css
import "swiper/css";
import { SlidesPerView } from "../../utils/slider";
import { VerticalSpacer } from "./vertical-spacer";

//
export function Testimonials() {
  //
  // const
  const [testimonials, setTestimonials] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);

  //fetch
  const fetchTestimonials = React.useCallback(async () => {
    setLoaded((_) => false);
    //config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // url
    const url = "testimonials/";
    await api
      .get(url, config)
      .then((r) => {
        if (r.status === 200) {
          setLoaded((_) => true);
          setTestimonials((_) => [...r.data]);
        }
      })
      .catch((e) => {
        setLoaded((_) => false);
        setTestimonials((_) => []);
      });
    //
  }, []);

  // get
  const loadTestimonials = (loaded) => {
    //check
    if (loaded) {
      return testimonials.map((v, i) => {
        //
        return (
          <SwiperSlide
            key={i}
            style={{
              padding: "0.875rem 0",
            }}
          >
            <figure className="snip1533">
              <figcaption>
                <blockquote>
                  <div className="py-3">
                    <BsFillPersonFill
                      size={30}
                      color={"var(--primary-color)"}
                    />
                  </div>
                  <p className="snip__text">{`"${v.testimonial_value}"`}</p>
                </blockquote>
                <h3>
                  {v.booker_t.user.first_name} {v.booker_t.user.last_name}
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AiFillStar color="yellow" size={20} />
                  <h5 className="text-muted">{v.rating_value}/5</h5>
                </div>
              </figcaption>
            </figure>
          </SwiperSlide>
        );
      });
    }
  };

  //
  React.useEffect(() => {
    fetchTestimonials();
  }, [fetchTestimonials]);

  //
  return (
    <div
      style={{
        padding: "0 1rem",
      }}
    >
      <VerticalSpacer height={"2rem"} />
      <SectionHeader
        title={"Testimonials"}
        subTitle={"WHAT OUR CUSTOMERS SAY ABOUT US"}
      />
      <VerticalSpacer height={"1rem"} />
      {/**/}
      <Swiper spaceBetween={10} slidesPerView={SlidesPerView()}>
        {loadTestimonials(loaded)}
      </Swiper>
    </div>
  );
}
