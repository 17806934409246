import React from "react";
import "swiper/css";
import "swiper/css/grid";
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionHeader } from "./section-header";
import { RowsPerView, SlidesPerView } from "../../utils/slider";

// import api
import api from "../../utils/api";

// sudo image
import sudo from "../../imgs/care.svg";
import { VerticalSpacer } from "./vertical-spacer";
import { Grid } from "swiper";

//
export function Gallery() {
  //galleryItems
  const [gallery, setGallery] = React.useState([]);
  const [successfullyLoaded, setSuccessfullyLoaded] = React.useState(false);
  // usecallback
  const fetchGallery = React.useCallback(async () => {
    setSuccessfullyLoaded((_) => false);
    // config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // url
    const url = "gallary/";
    await api
      .get(url, config)
      .then((r) => {
        //
        if (r.status === 200) {
          setSuccessfullyLoaded((_) => true);
          setGallery((_) => [...r.data]);
        }
      })
      .catch((e) => {
        setSuccessfullyLoaded((_) => false);
      });
  }, []);

  // load Images
  const LoadImages = (loaded) => {
    if (loaded) {
      return gallery.map((v, i) => {
        return (
          <SwiperSlide
            key={i}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="w-100"
              style={{
                aspectRatio: "16/9",
                objectFit: "cover",
              }}
              src={v.image_gallary}
              alt="gallery"
            />
          </SwiperSlide>
        );
      });
    }
  };

  // react hook
  React.useEffect(() => {
    //
    fetchGallery();
  }, [fetchGallery]);
  //
  return (
    <div
      style={{
        padding: "0 1rem",
      }}
    >
      <VerticalSpacer height={"2rem"} />
      <SectionHeader title={"Gallery"} />
      <VerticalSpacer height={"1rem"} />
      <Swiper
        modules={[Grid]}
        spaceBetween={10}
        slidesPerView={SlidesPerView()}
        grid={{
          rows: RowsPerView(),
          fill: true,
        }}
      >
        {LoadImages(successfullyLoaded)}
      </Swiper>
    </div>
  );
}
