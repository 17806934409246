import React from "react";
import { ScreenSize } from "./responsive";

import { SCREEN_TYPE } from "./strings";

// export
export function DETERMINE_SCREEN_TYPE() {
  //get size
  const size = ScreenSize();

  // check
  if (size > 0 && size <= 576) {
    return SCREEN_TYPE.mobile;
  } else if (size > 576 && size <= 768) {
    return SCREEN_TYPE.tablet;
  } else if (size > 768 && size <= 992) {
    return SCREEN_TYPE.desktop;
  } else if (size > 992 && size < 1200) {
    return SCREEN_TYPE.xl_screen;
  } else {
    return SCREEN_TYPE.xxl_screen;
  }
}
