export function GetTokens() {
  try {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (tokens) {
      return tokens;
    }
    // otherwise return
    return {};
  } catch (error) {
    return {};
  }
}
