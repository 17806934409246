import React from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";
import api from "../../utils/api";
import { LOADER_CONTEXT } from "../../utils/contexts";
import { TIMEOUT } from "../../utils/timeout";

//
export function RecoverAccount() {
  //ref
  const emailRef = React.useRef();
  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");
  const [validated, setValidated] = React.useState(false);
  const [disableUI, setDisableUI] = React.useState(false);

  // contexts
  const loading = React.useContext(LOADER_CONTEXT);

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  const handleSubmit = async (event) => {
    //
    window.scrollTo(0, 0);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    // once everything is cools
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    // setpayload
    const payload = {
      recovery_email: emailRef.current.value,
    };
    recoveryAccount(payload);
  };

  //
  const recoveryAccount = async (payload) => {
    // setloading
    loading.loading(true, "Recovering ...");
    setDisableUI((_) => true);
    setNotification(NOTIFICATION_TYPE.none, "", "");
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // url
    const url = "auth/api/recover-account/";
    await api
      .post(url, payload, config)
      .then(async (r) => {
        if (r.status === 200) {
          setDisableUI((_) => true);
          loading.loading(false, "");
          setNotification(NOTIFICATION_TYPE.success, "Yey!", `${r.data.msg}`);

          // save
          const p = {
            six_digit_pin: r.data.six_digit_pin,
            email: r.data.user_email,
            user_id: r.data.user_id,
          };

          // save locally
          localStorage.setItem("six_digit_pin", JSON.stringify(p));
          await TIMEOUT(3000);
          window.location.href = "/account-recovery/verify-otp";
        }
      })
      .catch((e) => {
        let errorMessage = "";
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.msg}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        } else {
          errorMessage = `${e}`;
        }
        setDisableUI((_) => false);
        loading.loading(false, "");
        setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
      });
  };

  // return
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            border: "none",
            backgroundColor: "var(--primary-color)",
            padding: "1rem 0",
          }}
        >
          <div className="h5">Account Recovery</div>
        </Card.Header>
        {/*Form*/}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {/*email*/}
          <Card.Body className="px-0">
            {/*notification*/}
            {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
            {/*notification*/}
            <Form.Group className="mb-3">
              <Form.Label>Enter Recovery Email</Form.Label>
              <Form.Control
                className="fw-bold"
                ref={emailRef}
                required={true}
                disabled={disableUI}
                placeholder="unitendlela@example.co.za"
                type="email"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Text>Your email is safe and sound with us</Form.Text>
              <Form.Control.Feedback type="invalid">
                Please provide a valid recovery email
              </Form.Control.Feedback>
            </Form.Group>
            {/*email*/}
          </Card.Body>
          <Card.Footer
            style={{
              border: "none",
              backgroundColor: "var(--primary-color-card)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gap: "0.6rem",
            }}
          >
            <Button
              // disabled={locs.length < 1}
              disabled={disableUI}
              className="btn-sm"
              variant="secondary"
              type="submit"
              onClick={() => {}}
            >
              RETRIEVE OTP
            </Button>
          </Card.Footer>
        </Form>
        {/*Form*/}
      </Card>
    </Container>
  );
}
