import React from "react";
// import
import { Navbar } from "./components/sub-components/navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import "./custom.scss";
import "react-datepicker/dist/react-datepicker.css";

import { BsWhatsapp } from "react-icons/bs";

import { LOADER_CONTEXT } from "./utils/contexts";
import { MainPage } from "./components/pages/main-page";
import { Footer } from "./components/sub-components/footer";
import { SigninPage } from "./components/pages/signin";
import { SignUp } from "./components/pages/Signup";
import { FAQs } from "./components/pages/faqs";
import { Bookings } from "./components/pages/bookings";
import { Profile } from "./components/pages/profile";
import { UpdateBooking } from "./components/sub-components/update-booking";
import { RecoverAccount } from "./components/pages/recover-account";
import { VerifyOTP } from "./components/pages/verify-otp";
import { ResetPassword } from "./components/pages/reset-password";
import { FeedBack } from "./components/pages/feed-back";

// utils
import jwt_decode from "jwt-decode";
import { isLoggedin } from "./utils/isloggedin";
import { GetTokens } from "./utils/retrieve_tokens";
import { VerticalSpacer } from "./components/sub-components/vertical-spacer";

function App() {
  // message
  const [msg, setMsg] = React.useState("");
  // variables
  const loaderRef = React.useRef(null);

  const loading = (isLoading, message) => {
    //
    if (isLoading) {
      ///
      setMsg((_) => message);
      loaderRef.current.classList.add("active");
      return;
    }
    // remove
    setMsg((_) => "");
    loaderRef.current.classList.remove("active");
    return;
  };

  // Log user out
  React.useEffect(() => {
    // islogged in
    const loggedin = isLoggedin();
    if (loggedin) {
      // get tokens
      const tokens = GetTokens(); // access token and refresh token
      // decode the token
      const decodedAccessToken = jwt_decode(tokens.access);
      // expiary date
      const expEpochs = decodedAccessToken.exp * 1000; // milliseconds
      const currentEpochs = Date.now();

      // verify if token is valid
      const isValidToken = expEpochs - currentEpochs < 0;
      //
      if (isValidToken) {
        // logout
        localStorage.clear(); // remove everything
        return;
      }
    }
  }, []);

  //
  return (
    <div className="parent">
      {/*first child*/}
      <BrowserRouter basename="/">
        <div className="first_child">
          <LOADER_CONTEXT.Provider
            value={{
              loading: loading,
            }}
          >
            <Navbar />
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/sign-in" element={<SigninPage />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/faq" element={<FAQs />} />
              <Route path="/bookings" element={<Bookings />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/account-recovery" element={<RecoverAccount />} />
              <Route path="/feed-back" element={<FeedBack />} />
              <Route
                path="/account-recovery/reset-password"
                element={<ResetPassword />}
              />
              <Route
                path="/account-recovery/verify-otp"
                element={<VerifyOTP />}
              />
              <Route
                path="/bookings/booking/:id/update"
                element={<UpdateBooking />}
              />
            </Routes>
            {/* 
            <SignUp /> */}
            <VerticalSpacer height={"100px"} />
            <Footer />
          </LOADER_CONTEXT.Provider>
        </div>
        {/*first child*/}
        {/*scond child*/}
        <div className="second_child" ref={loaderRef}>
          <div className="spinner-border text-white" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "600",
              fontSize: "1.2rem",
            }}
          >
            {msg}
          </div>
        </div>
        {/*scond child*/}
        <div className="position-fixed bottom-0 end-0 p-4">
          <a
            className="btn btn-primary border-0 p-1 m-0 rounded-1 d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "rgb(37, 211, 102)",
            }}
            href="https://wa.me/0639229660?text=untransport%20services"
            target="_blank"
            rel="noreferrer"
          >
            <BsWhatsapp size={20} />
          </a>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
