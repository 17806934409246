import React from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";
import api from "../../utils/api";
import { LOADER_CONTEXT } from "../../utils/contexts";
import { TIMEOUT } from "../../utils/timeout";
//
export function VerifyOTP() {
  //
  const otpRef = React.useRef();

  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");
  const [validated, setValidated] = React.useState(false);
  const [disableUI, setDisableUI] = React.useState(false);

  // contexts
  const loading = React.useContext(LOADER_CONTEXT);

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  const handleSubmit = async (event) => {
    //
    window.scrollTo(0, 0);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // once everything is cools
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    compareTwoDigits();
  };

  //
  const check = () => {
    try {
      const six = JSON.parse(localStorage.getItem("six_digit_pin"));
      if (six) {
        return [true, six];
      }
      return [false, {}];
    } catch (error) {
      return [false, {}];
    }
  };

  // compaire two numbers
  const compareTwoDigits = async () => {
    setDisableUI((_) => true);
    setNotification(NOTIFICATION_TYPE.none, "", "");
    //check
    const [verify, six] = check();
    if (verify) {
      if (otpRef.current.value === `${six.six_digit_pin}`) {
        setDisableUI((_) => true);
        setNotification(
          NOTIFICATION_TYPE.success,
          "Yey!",
          "OTP's match successfully"
        );
        await TIMEOUT(3000);
        window.location.href = "/account-recovery/reset-password";
        return;
      }
      //
      setDisableUI((_) => false);
      setNotification(
        NOTIFICATION_TYPE.error,
        "Oops!",
        "OTP's dont match please try again"
      );
    }
  };
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            border: "none",
            backgroundColor: "var(--primary-color)",
            padding: "1rem 0",
          }}
        >
          <div className="h5">OTP Verification</div>
        </Card.Header>
        {/*form*/}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Card.Body className="px-0">
            {/*notification*/}
            {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
            {/*notification*/}
            <Form.Group className="mb-3">
              <Form.Label>Enter OTP</Form.Label>
              <Form.Control
                className="fw-bold"
                required={true}
                ref={otpRef}
                disabled={disableUI}
                placeholder="e.g 859456"
                maxLength={6}
                minLength={6}
                type="tel"
              />

              <Form.Control.Feedback type="invalid">
                Please provide a valid phone number
              </Form.Control.Feedback>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Card.Body>
          <Card.Footer
            style={{
              border: "none",
              backgroundColor: "var(--primary-color-card)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gap: "0.6rem",
            }}
          >
            <Button
              className="btn-sm"
              variant="secondary"
              disabled={disableUI}
              type="submit"
            >
              RECOVER
            </Button>
          </Card.Footer>
        </Form>
        {/*form*/}
      </Card>
    </Container>
  );
}
