import React from "react";
import { Button, Card, Container, Form } from "react-bootstrap";

import { BOOKING_MODAL } from "./modals";
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";
import { LOADER_CONTEXT } from "../../utils/contexts";
import { BOOKING_CONTEXT } from "../../utils/contexts";
import api from "../../utils/api";
import { TIMEOUT } from "../../utils/timeout";
import { Link } from "react-router-dom";

// function

export function SignUp() {
  //ref
  const emailRef = React.useRef();
  const firstNameRef = React.useRef();
  const lastNameRef = React.useRef();
  const phoneNumberRef = React.useRef();
  const passwordRef = React.useRef();
  const selectRef = React.useRef();
  const termsAndConditionsRef = React.useRef();
  //re
  const [validated, setValidated] = React.useState(false);
  const [disableUI, setDisableUI] = React.useState(false);

  //notification
  const [showModal, setShowModal] = React.useState(false);
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");

  // contexts
  const loading = React.useContext(LOADER_CONTEXT);

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  // retrieve existing job
  const checkExistingBooking = () => {
    try {
      const bQuote = JSON.parse(localStorage.getItem("booking-quote"));
      if (bQuote) {
        return [true, bQuote];
      }
      return [false, {}];
    } catch (error) {
      return [false, {}];
    }
  };

  const handleSubmit = async (event) => {
    //
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      window.scrollTo(0, 0);
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // once everything is cools
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    // payload

    const payload = {
      email: `${emailRef.current.value}`.toLowerCase(),
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      mobile_number: phoneNumberRef.current.value,
      password: passwordRef.current.value,
      is_customer: true,
      is_driver: false,
    };

    //
    await REGISTER(payload);
  };

  // register
  const REGISTER = async (payload) => {
    //
    loading.loading(true, "Registering ... ");
    setDisableUI((_) => true);
    setNotification(NOTIFICATION_TYPE.none, "", "");

    // header
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //
    const url = "auth/api/register/";
    await api
      .post(url, payload, config)
      .then(async (r) => {
        if (r.status === 201) {
          window.scrollTo(0, 0);
          loading.loading(false, "");
          setDisableUI((_) => true);
          setNotification(
            NOTIFICATION_TYPE.success,
            "Yey!",
            "You have successfully registered"
          );

          // await
          await TIMEOUT(3000);

          // sign
          await SIGNIN_USER({
            email: `${payload.email}`.toLowerCase(),
            password: payload.password,
          });
        }
      })
      .catch((e) => {
        let errorMessage = "";
        console.log(e);
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.email[0]}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        } else {
          errorMessage = `${e}`;
        }
        window.scrollTo(0, 0);
        loading.loading(false, "");
        setDisableUI((_) => false);
        setNotification(
          NOTIFICATION_TYPE.error,
          "Oops! error.",
          `${errorMessage}`
        );
      });
  };

  //
  const SIGNIN_USER = async (payload) => {
    //
    loading.loading(true, "Signin in ...");
    setDisableUI((_) => true);
    setNotification(NOTIFICATION_TYPE.none, "", "");
    // header
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //
    const url = "auth/api/token/";
    await api
      .post(url, payload, config)
      .then(async (r) => {
        // check
        if (r.status === 200) {
          window.scrollTo(0, 0);
          loading.loading(false, "");
          setDisableUI((_) => true);
          setNotification(NOTIFICATION_TYPE.success, "Yey!", "Success Welcome");

          // save the tokens
          const tokens = {
            access: r.data.access,
            refresh: r.data.refresh,
          };
          //
          localStorage.setItem("tokens", JSON.stringify(tokens));
          //check if there a booking
          const [verify, _] = checkExistingBooking();
          if (verify) {
            setShowModal((_) => true);
            return;
          }

          await TIMEOUT(3000);
          window.location.href = "/";
          return;
        }
      })
      .catch((e) => {
        let errorMessage = "";
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.msg}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        } else {
          errorMessage = `${e}`;
        }
        window.scrollTo(0, 0);
        setDisableUI((_) => false);
        loading.loading(false, "");
        setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
      });
    //
  };

  // ret
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            border: "none",
            backgroundColor: "var(--primary-color)",
            padding: "1rem 0",
          }}
        >
          <div className="h5" style={{ fontWeight: "800" }}>
            SIGN UP
          </div>
        </Card.Header>
        <Card.Body className="px-0">
          {/*notification*/}
          {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
          {/*notification*/}
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/*email*/}
            <Form.Group className="mb-3">
              <Form.Label>Enter Email</Form.Label>
              <Form.Control
                className="fw-bold"
                ref={emailRef}
                required={true}
                disabled={disableUI}
                placeholder="unitendlela@example.co.za"
                type="email"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address
              </Form.Control.Feedback>
              <Form.Text>Your email is safe and sound with us</Form.Text>
            </Form.Group>
            {/*email*/}

            {/*First Name*/}
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                className="fw-bold"
                required={true}
                ref={firstNameRef}
                disabled={disableUI}
                placeholder="France"
                type="text"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid first name
              </Form.Control.Feedback>
            </Form.Group>
            {/*First Name*/}

            {/*last name*/}
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                className="fw-bold"
                required={true}
                ref={lastNameRef}
                disabled={disableUI}
                placeholder="Ndlela"
                type="text"
              />

              <Form.Control.Feedback type="invalid">
                Please provide a valid last name
              </Form.Control.Feedback>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            {/*last name*/}
            {/*phone number*/}
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                className="fw-bold"
                required={true}
                ref={phoneNumberRef}
                disabled={disableUI}
                placeholder="080 000 0000"
                maxLength={10}
                minLength={10}
                type="tel"
              />

              <Form.Control.Feedback type="invalid">
                Please provide a valid phone number
              </Form.Control.Feedback>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            {/*phone number*/}
            {/*Password*/}
            <Form.Group className="mb-3">
              <Form.Label>Choose Password</Form.Label>
              <Form.Control
                className="fw-bold"
                required={true}
                ref={passwordRef}
                disabled={disableUI}
                placeholder="enter your password"
                type="password"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                Please provide a valid password between 4-8 digits to the
                entered Email Address
              </Form.Control.Feedback>
              <Form.Text>
                Password entered must be atleast 8 characters long.
              </Form.Text>
            </Form.Group>
            {/*Password*/}
            {/*select*/}
            <Form.Group>
              <Form.Label>Sign up as ?</Form.Label>
              <Form.Select ref={selectRef} className="fw-bold">
                <option value="customer">Customer</option>
                <option disabled={true} value="Driver">
                  Driver
                </option>
              </Form.Select>
            </Form.Group>
            {/*select*/}
            {/*accept terms and conditions*/}
            <Form.Group className="mt-3">
              <Form.Check
                label="Agree to our terms and conditions"
                required={true}
                disabled={disableUI}
                ref={termsAndConditionsRef}
              />
            </Form.Group>
            {/*accept terms and conditions*/}

            <Button
              variant="secondary"
              className="w-100 my-3"
              disabled={disableUI}
              size="sm"
              type="submit"
            >
              SIGN UP
            </Button>

            {/*Forgot passwords*/}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div>
                Already have an account?{" "}
                <Link
                  className="nav__links"
                  to="/sign-in"
                  reloadDocument={true}
                >
                  Sign In
                </Link>
              </div>
            </div>
            {/*Forgot passwords*/}
          </Form>
          {/*context*/}
          <BOOKING_CONTEXT.Provider
            value={{
              show: showModal,
              setShow: setShowModal,
            }}
          >
            <BOOKING_MODAL />
          </BOOKING_CONTEXT.Provider>
          {/*context*/}
        </Card.Body>
      </Card>
    </Container>
  );
}
