import house from "../imgs/house.svg"; //../imgs/house.svg
import distance from "../imgs/100.svg";
import cart from "../imgs/cart.svg";
import garden from "../imgs/garden.svg";

// why choose us?
import discount from "../imgs/discount.svg";
import drivers from "../imgs/driver.svg";
import helpers from "../imgs/helpers.svg";
import money from "../imgs/money.svg";
import percentage from "../imgs/percentage.svg";
import services from "../imgs/services.svg";
import care from "../imgs/care.svg";
import quote from "../imgs/quote.svg";
import packing from "../imgs/packaging.svg";
import reliable_transport from "../imgs/reliable_transport.svg";

// vehicles
// import oneTon from "../imgs/vehicle/1_ton.svg";
// import oneFiveTon from "../imgs/vehicle/1_5_tons.svg";
// import twoTons from "../imgs/vehicle/2_tons.svg";
// import threeTons from "../imgs/vehicle/3_tons.svg";
// import eightTons from "../imgs/vehicle/8_tons.svg";
// export

export const coverTextTitle = "Need a Reliable Transportation Service?";
export const coverTextSubTitle =
  "Need to move your Apartment, Office, House furniture or dispose your garden refuse? Unite Ndlela is the answer to your needs, it's our speciality";
export const ABOUT_US =
  "Unite Ndlela Transport Services is a fast growing furniture and other items moving company which offers simple, reliable and cost effective services to its customers whenever and wherever a bakkie or truck is required to move furniture";

export const COVERAGE =
  "Unite Ndlela operates in all areas in the Gauteng Province, Unite Ndlela does long distance trips Nationwide from Gauteng and from other provinces to Gauteng";

export const PRIVACY_P =
  "Copyright 2022 Unite Ndlela Transport (Pty) Ltd. All rights reserved. Use of this webapp is regulated by our webapp";

export const CONTACT_NUMBERS = "081 419 4289 | 078 814 0672";
export const LOCATION = "Clayville East, Olifantsfontein 1666";
export const EMAIL = "admin@untrans.co.zs";
export const GOOGLE_API_KEY = "AIzaSyCkMK3usAJzg4dI_ANOvNZeNaoTrgsmnjg";

//
export const VEHICLES = [
  {
    vsize: 1,
    img: "https://storage.googleapis.com/unmovers_media/app-images/1_ton.svg",
    caption: "1 Ton",
    description:
      "is good when loading a double bed, single door fridge, a washing machine, dishwasher or tumble dryer machine and a few other stuff E.G boxes and plastics",
  },
  {
    vsize: 1.5,
    img: "https://storage.googleapis.com/unmovers_media/app-images/1_5_tons.svg",
    caption: "1.5 Ton",
    description:
      "is good when loading a double bed, 2 or 3 seater couch, double door fridge, a washing machine and a few other items E.G boxes and plastics",
  },
  {
    vsize: 2.0,
    img: "https://storage.googleapis.com/unmovers_media/app-images/two_tons.svg",
    caption: "2 Ton",
    description:
      "is suitable when loading 2 double beds, couches, double door fridge, washing and dishwasher machines and a few boxes and other items",
  },
  {
    vsize: 3.0,
    img: "https://storage.googleapis.com/unmovers_media/app-images/3_tons.svg",
    caption: "3 Ton",
    description:
      "is suitable for 2 bedrooms, kitchen, sitting and dining room and a few boxes/small items",
  },
  {
    vsize: 8.0,
    img: "https://storage.googleapis.com/unmovers_media/app-images/8_tons.svg",
    caption: "8 Ton",
    description:
      "is suitable when moving more big items especially when moving a 2 or 3 bedroom house with kitchen, sitting and dining rooms",
  },
];
//
export const OUR_SERVICES = [
  {
    title: "Home",
    img: house,
    subTitle: "Relocating from your house, apartment or office",
  },
  {
    title: "Disposing",
    img: garden,
    subTitle: "Disposing your garden refuse",
  },
  {
    title: "Collections",
    img: cart,
    subTitle:
      "Collecting any purchased goods and deliver them to your required destination",
  },
  {
    title: "Long Distance",
    img: distance,
    subTitle: "Long distance deliveries",
  },
];

export const WHY_CHOOSE_US = [
  {
    title: "Cost",
    img: money,
    subTitle: "We provide competitive and cost effective prices",
  },
  {
    title: "Drivers",
    img: drivers,
    subTitle: "Our Drivers are experts in the industry",
  },
  {
    title: "Helpers",
    img: helpers,
    subTitle: "We bring extra trained helpers at request",
  },
  {
    title: "Equipment",
    img: care,
    subTitle:
      "In case of rain, we protect your goods from any damages that may arise from the rain",
  },
  {
    title: "Handle With Care",
    img: care,
    subTitle:
      "We pride ourselves in simplifying your move and handling goods with care",
  },
  {
    title: "Tolerance",
    img: percentage,
    subTitle: "Our motto is 0% damage tolerance",
  },
  {
    title: "Services",
    img: services,
    subTitle: "We provide exceptional and professional services",
  },
  {
    title: "Vehicles",
    img: reliable_transport,
    subTitle:
      "We have the most reliable and well Maintained vehicles to ensure your goods are delivered without any delays due to breakdowns",
  },
  {
    title: "Discounts",
    img: discount,
    subTitle: "We offer 10% midmonth discount.",
  },
  {
    title: "Instant Quote",
    img: quote,
    subTitle: "Get an instant quote and make a booking in less than 5 minutes.",
  },
  {
    title: "Packing and Unpacking",
    img: packing,
    subTitle:
      "We do house/flat/office packing and unpacking at an extra cost upon customer request.",
  },
];

// createbooking steps
export const STEPS = {
  vehicle_size: "vehicle_size",
  location: "location",
  datetime: "DateTime",
  addinformation: "addinformation",
  quote: "quote",
};

export const NOTIFICATION_TYPE = {
  error: "error",
  success: "success",
  warning: "warning",
  info: "information",
  none: "none",
};

export const SCREEN_TYPE = {
  mobile: "mobile",
  tablet: "tablet",
  desktop: "desktop",
  xl_screen: "xl_screen",
  xxl_screen: "xxl_screen",
};
