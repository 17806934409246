import React from "react";
import { Carousel, Card, Button, ButtonGroup } from "react-bootstrap";

import { WHY_CHOOSE_US } from "../../utils/strings";
import { SectionHeader } from "./section-header";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { VerticalSpacer } from "./vertical-spacer";
// courasel
export function WhyChooseUs() {
  // header
  const title = "Why Choose Us?";
  const subTitle = "HERE'S WHAT WE OFFER";
  //
  // active index
  const [activeIndex, setActiveIndex] = React.useState(0);

  // next
  const nextIndex = () => {
    if (activeIndex < WHY_CHOOSE_US.length - 1) {
      setActiveIndex((v) => activeIndex + 1);
    }
  };

  // previous
  const prevIndex = () => {
    if (activeIndex > 0) {
      setActiveIndex((v) => activeIndex - 1);
    }
  };

  const onSelect = (selected, v) => {
    setActiveIndex((e) => selected);
  };

  // return
  return (
    <div
      style={{
        padding: "0 1rem",
      }}
    >
      <VerticalSpacer height={"2rem"} />
      <SectionHeader title={title} subTitle={subTitle} />
      <VerticalSpacer height={"1rem"} />
      <Carousel
        activeIndex={activeIndex}
        onSelect={onSelect}
        controls={false}
        indicators={false}
      >
        {WHY_CHOOSE_US.map((v, i) => {
          return (
            <Carousel.Item key={i}>
              <Card
                className="text-center border-0"
                style={{
                  backgroundColor: "var(--primary-color-card)",
                }}
              >
                <Card.Body>
                  <Card.Title>{v.title}</Card.Title>
                  <img className="w-25 py-4" src={v.img} alt="" />
                  <p>{v.subTitle}</p>
                </Card.Body>
              </Card>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "1rem 0",
          gap: "10px",
        }}
      >
        <IoIosArrowBack
          onClick={prevIndex}
          size={25}
          color="var(--secondary-color)"
        />
        <IoIosArrowForward
          onClick={nextIndex}
          size={25}
          color="var(--secondary-color)"
        />
      </div>
    </div>
  );
}
