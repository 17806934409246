import React from "react";
import { Accordion, Card, Container } from "react-bootstrap";

import { LOADER_CONTEXT } from "../../utils/contexts";

import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";

import api from "../../utils/api";
// export
export function FAQs() {
  // useState
  const [faqs, setFaqs] = React.useState([]);
  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");

  // context
  const loader = React.useContext(LOADER_CONTEXT);
  //
  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  const getFAQs = React.useCallback(async () => {
    //
    loader.loading(true, "");
    setNotification(NOTIFICATION_TYPE.none, "", "");
    // config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //
    const url = "faqs/";
    await api
      .get(url, config)
      .then((r) => {
        //
        if (r.status === 200) {
          loader.loading(false, "");
          setNotification(NOTIFICATION_TYPE.none, "", "");
          setFaqs((_) => r.data);
        }
      })
      .catch((e) => {
        // configure message
        let errorMessage = "";
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.msg}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        }
        setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
        loader.loading(false, "");
      });
    //
  }, []);
  //
  // react
  React.useEffect(() => {
    getFAQs();
  }, [getFAQs]);

  //
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            color: "#000",
            border: "0",
            padding: "0",

            backgroundColor: "transparent",
          }}
        >
          <div className="h4 py-2" style={{ fontWeight: "800" }}>
            {" "}
            FAQ
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {/*notification*/}
          {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
          {/*notification*/}
          <Accordion defaultActiveKey="0">
            {faqs.map((v, i) => {
              return (
                <Accordion.Item eventKey={`${i}`} key={i}>
                  <Accordion.Header>
                    {v.question} {"? "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Answer:
                    </span>{" "}
                    {v.answer}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
}
