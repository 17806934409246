import React from "react";
import { Button, Card, Carousel, Form } from "react-bootstrap";
import { VEHICLES } from "../../../utils/strings";
import { CONTINUE_CONTEXT } from "../../../utils/contexts";
import { STEPS } from "../../../utils/strings";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

// vehicles

// function
export function UpdateChooseVehicle() {
  //
  // use conext
  const context = React.useContext(CONTINUE_CONTEXT);
  // active index
  const [selectedVehicle, setSelectedVehicle] = React.useState(
    VEHICLES.findIndex((v) => v.vsize === context.booking.vehicle_type)
  );

  // onNext
  const onNext = () => {
    //
    if (selectedVehicle < VEHICLES.length - 1) {
      setSelectedVehicle((_) => selectedVehicle + 1);
    }
  };
  const onPrev = () => {
    if (selectedVehicle > 0) {
      setSelectedVehicle((_) => selectedVehicle - 1);
    }
  };
  // on continue
  const onContinue = () => {
    // save to local storage
    localStorage.setItem(
      "update-vehicle-size",
      VEHICLES[selectedVehicle].vsize
    );
    context.onContinue(STEPS.vehicle_size);
  };

  // booking canceled
  const bookingCancelled = (cancelled) => {
    if (cancelled) {
      return <div className="h4 text-danger">CANCELED</div>;
    }
  };

  //
  return (
    <Card className="dynamic_card border-0">
      <Card.Header
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          border: "none",
          backgroundColor: "var(--primary-color)",
        }}
      >
        <div>Step 1 of 5</div>
        <div className="h5">Choose Vehicle</div>
      </Card.Header>
      <Card.Body className="text-center px-0">
        {bookingCancelled(context.booking.booking_cancelled)}
        <Carousel
          activeIndex={selectedVehicle}
          controls={false}
          style={{
            position: "relative",
          }}
        >
          {VEHICLES.map((v, i) => {
            return (
              <Carousel.Item key={i}>
                <img
                  className="d-block w-100"
                  src={v.img}
                  alt={`${v.caption}`}
                />
                <Card.Title>Current vehicle selection: {v.caption}</Card.Title>
                <Card.Subtitle className="my-3 text-danger">
                  Vehicle selection invoice {context.booking.id}:{" "}
                  {context.booking.vehicle_type} {" Ton"}
                </Card.Subtitle>
                <Card.Text>
                  <span className="fw-bold"> {`${v.vsize} ton`}</span>{" "}
                  {v.description}
                </Card.Text>
              </Carousel.Item>
            );
          })}
          {/*check*/}
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <IoIosArrowBack
              onClick={onPrev}
              size={30}
              color="var(--secondary-color)"
            />
            <IoIosArrowForward
              onClick={onNext}
              size={30}
              color="var(--secondary-color)"
            />
          </div>
        </Carousel>
        {/*check*/}

        {/*Btns*/}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: "1.5rem 0",
            flexWrap: "wrap",
          }}
        >
          {VEHICLES.map((v, i) => {
            return (
              <Form.Group
                key={i}
                style={{
                  fontSize: "0.875rem",
                }}
              >
                <Form.Check
                  key={i}
                  checked={VEHICLES[selectedVehicle].vsize === v.vsize}
                  onChange={(e) => {}}
                  inline={true}
                  value={v.vsize}
                  label={`${v.caption}`}
                  type={"radio"}
                  name={"vehicle_size"}
                  onClick={(e) => {
                    const currentIndex = VEHICLES.findIndex(
                      (v) => `${v.vsize}` === `${e.currentTarget.value}`
                    );
                    setSelectedVehicle((_) => currentIndex);
                  }}
                />
              </Form.Group>
            );
          })}
        </div>
        {/*Btns*/}
      </Card.Body>
      <Card.Footer
        style={{
          border: "none",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "var(--primary-color-card)",
        }}
      >
        <Button className="btn-sm" variant="secondary" onClick={onContinue}>
          CONTINUE
        </Button>
      </Card.Footer>
    </Card>
  );
}
