import { NOTIFICATION_TYPE } from "./strings";

export function NOTIFICATION(
  notificationType,
  leadingMessage,
  trailingMessage
) {
  switch (notificationType) {
    case NOTIFICATION_TYPE.error:
      return (
        <div className="alert alert-danger" role="alert">
          <div>
            <span className="fw-bold">
              {leadingMessage}{" "}
              <span className="fw-normal">{trailingMessage}</span>
            </span>
          </div>
        </div>
      );
    case NOTIFICATION_TYPE.success:
      return (
        <div className="alert alert-success" role="alert">
          <div>
            <span className="fw-bold">
              {leadingMessage}{" "}
              <span className="fw-normal">{trailingMessage}</span>
            </span>
          </div>
        </div>
      );
    case NOTIFICATION_TYPE.warning:
      return (
        <div className="alert alert-warning" role="alert">
          <div>
            <span className="fw-bold">
              {leadingMessage}{" "}
              <span className="fw-normal">{trailingMessage}</span>
            </span>
          </div>
        </div>
      );
    case NOTIFICATION_TYPE.info:
      return (
        <div className="alert alert-info" role="alert">
          <div>
            <span className="fw-bold">
              {leadingMessage}{" "}
              <span className="fw-normal">{trailingMessage}</span>
            </span>
          </div>
        </div>
      );
    default:
      return;
  }
}
