import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { COVERAGE, SCREEN_TYPE } from "../../utils/strings";
import { SectionHeader } from "./section-header";
import { VerticalSpacer } from "./vertical-spacer";

import locationImg from "../../imgs/location.svg";
import { DETERMINE_SCREEN_TYPE } from "../../utils/screen-type";

export function Coverage() {
  //
  const widget = () => {
    return (
      <Col
        sm={3}
        md={3}
        style={{
          padding: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img className="w-100" src={locationImg} alt="Location" />
      </Col>
    );
  };
  const choose = () => {
    switch (DETERMINE_SCREEN_TYPE()) {
      case SCREEN_TYPE.tablet:
        return widget();
      case SCREEN_TYPE.desktop:
        return widget();
      case SCREEN_TYPE.xl_screen:
        return widget();
      case SCREEN_TYPE.xxl_screen:
        return widget();
      default:
        return;
    }
  };
  return (
    <div>
      <VerticalSpacer height={"2rem"} />
      <Row
        xs={1}
        md={2}
        style={{
          padding: "0 1.5rem",
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 0,
          }}
          sm={9}
          md={9}
        >
          <SectionHeader title={"Coverage"} subTitle={"OUR REACH"} />
          <Card className="border-0 text-center">
            <Card.Body>
              <Card.Text>{COVERAGE}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {choose()}
      </Row>
      <VerticalSpacer height={"60px"} />
    </div>
  );
}
