import React from "react";

// icons
import { BsFillTelephoneFill, BsInstagram } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail, MdOutlineFacebook } from "react-icons/md";

import pdf from "../../pdf/terms-and-conditions.pdf";

// string s
import {
  CONTACT_NUMBERS,
  EMAIL,
  LOCATION,
  PRIVACY_P,
} from "../../utils/strings";

//
export function Footer() {
  // ret
  return (
    <React.Fragment>
      <div className="footer-class">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "0.875rem",
            padding: "1rem",
          }}
        >
          <div>
            <div>
              <BsFillTelephoneFill />
              {CONTACT_NUMBERS}
            </div>
          </div>
          <div>
            <div>
              <HiLocationMarker />
              {LOCATION}
            </div>
          </div>
          <div>
            <div>
              <MdEmail />
              {EMAIL}
            </div>
          </div>
        </div>
        {/*follow us*/}
        <div className="h5 fw-bold">Follow Us</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.875rem",
            // padding: "0.875rem",
            padding: "0.125rem 0.875rem 0.875rem 0.875rem",
          }}
        >
          <a
            href="https://web.facebook.com/UniteNdlela"
            target={"_blank"}
            rel="noreferrer"
          >
            <MdOutlineFacebook size={25} color="white" />
          </a>
          <a
            href="https://www.instagram.com/unitendlela/"
            target={"_blank"}
            rel="noreferrer"
          >
            <BsInstagram size={20} color="white" />
          </a>
        </div>
      </div>

      {/**/}
      <div
        style={{
          display: "flex",
          textAlign: "center",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <div
          style={{
            fontSize: "0.875rem",
          }}
        >
          &copy; {PRIVACY_P}{" "}
          <a
            style={{
              fontSize: "0.875rem",
            }}
            className="nav__links"
            href={pdf}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          <span>and </span>
          <a
            style={{
              fontSize: "0.875rem",
            }}
            className="nav__links"
            href={pdf}
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
