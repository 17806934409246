import React from "react";
import { Button, Overlay, Table, Tooltip } from "react-bootstrap";

// icons
import { BsInfoCircle } from "react-icons/bs";

// quote invoice
export function QuoteInvoice({ payload }) {
  const [showBase, setShowBase] = React.useState(false);
  const [showOffPeak, setShowOffPeak] = React.useState(false);
  const basefeeRef = React.useRef(null);
  const offpeakRef = React.useRef(null);

  // format
  const formater = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "zar",
    minimumSignificantDigits: 1,
  });

  //
  return (
    <Table bordered>
      <thead className="w-100 border-0">
        <tr className="text-white border-0 bg-primary">
          <th className="border-0">Description</th>
          <th className="border-0">Total</th>
        </tr>
      </thead>
      <tbody className="w-100">
        <tr>
          <td>
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              BASE FEE{" "}
              <Button
                className="border-0 bg-transparent p-0"
                ref={basefeeRef}
                onClick={() => setShowBase(!showBase)}
              >
                <BsInfoCircle color="var(--secondary-color)" />
              </Button>
            </span>

            <Overlay
              target={basefeeRef.current}
              show={showBase}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Booking amount including number of helpers and stairs.
                </Tooltip>
              )}
            </Overlay>
          </td>
          <td>
            {formater
              .format(payload.base_quote_price + payload.mid_month_discount)
              .replace("ZA", "")}
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: "600" }}>DISCOUNTS</span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              OFF PEAK{" "}
              <Button
                className="border-0 bg-transparent p-0"
                ref={offpeakRef}
                onClick={() => setShowOffPeak(!showOffPeak)}
              >
                <BsInfoCircle color="var(--secondary-color)" />
              </Button>
            </span>

            <Overlay
              target={offpeakRef.current}
              show={showOffPeak}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Discount given at the middle of the month.
                </Tooltip>
              )}
            </Overlay>
          </td>
          <td
            style={{
              color: "red",
            }}
          >
            {formater.format(payload.mid_month_discount).replace("ZA", " - ")}
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: "600" }}>AMOUNT DUE</span>
          </td>
          {/* <td>{payload.generate_quote_price}</td> */}
          <td>{formater.format(payload.base_quote_price).replace("ZA", "")}</td>
        </tr>
      </tbody>
    </Table>
  );
}
