import React from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";

// api
import api from "../../utils/api";

// context
import { LOADER_CONTEXT } from "../../utils/contexts";
import { isLoggedin } from "../../utils/isloggedin";
import { GetTokens } from "../../utils/retrieve_tokens";
import { TIMEOUT } from "../../utils/timeout";
// profile
export function Profile() {
  //contexts
  const loader = React.useContext(LOADER_CONTEXT);
  //refs
  const emailRef = React.useRef();
  const firstNameRef = React.useRef();
  const lastNameRef = React.useRef();
  const phoneNumberRef = React.useRef();

  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");
  const [validated, setValidated] = React.useState(false);
  const [disableUI, setDisableUI] = React.useState(false);

  // customer profile
  const [customerProfile, setCustomerProfile] = React.useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
  });

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  const handleSubmit = async (event) => {
    //
    verifyAndPopulate();
    window.scrollTo(0, 0);
    //
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // once everything is cools
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    //
    const payload = {
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      mobile_number: phoneNumberRef.current.value,
      //   email: "",
    };
    await updateCustomerProfile(payload);
  };

  // update customer profile
  const updateCustomerProfile = async (payload) => {
    // check if user logged in
    if (isLoggedin()) {
      //
      loader.loading(true, "Updating Profile ...");
      setNotification(NOTIFICATION_TYPE.none, "", "");
      setDisableUI((_) => true);

      // get tokens
      const token = GetTokens();
      // config
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      };

      // url
      const url = "auth/api/update/user/";
      await api
        .put(url, payload, config)
        .then(async (r) => {
          if (r.status === 200) {
            window.scrollTo(0, 0);
            loader.loading(false, "");
            setNotification(
              NOTIFICATION_TYPE.success,
              "Yey!",
              "Profile successfully updated"
            );
            setDisableUI((_) => false);
            //
            await TIMEOUT(3000);
            window.location.href = "/profile";
          }
        })
        .catch((e) => {
          window.scrollTo(0, 0);
          loader.loading(false, "Updating Profile ...");
          setNotification(NOTIFICATION_TYPE.error, "Oops!", `${e}`);
          setDisableUI((_) => false);
        });

      return;
    }

    // otherwise reroute
    window.location.href = "/sign-in";
  };

  // function to retrieve customer details
  const getCustomerProfile = React.useCallback(async () => {
    // check if logged in
    if (isLoggedin()) {
      //
      loader.loading(true, "Fetching Profile ...");
      setDisableUI((_) => true);
      setNotification(NOTIFICATION_TYPE.none, "", "");
      // this measns we all fine
      // get tokens
      const token = GetTokens();
      // setconfig
      // config
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      };

      // const
      const url = "auth/api/get/user-account/";
      await api
        .get(url, config)
        .then((r) => {
          if (r.status === 200) {
            loader.loading(false, "");
            setDisableUI((_) => false);
            setNotification(NOTIFICATION_TYPE.none, "", ``);
            // set customer profile information
            setCustomerProfile((_) => {
              return {
                first_name: r.data.first_name,
                last_name: r.data.last_name,
                mobile_number: r.data.mobile_number,
                email: r.data.email,
              };
            });
          }
        })
        .catch((e) => {
          let errorMessage = "";
          if (e.response.status === 401) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 404) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 400) {
            errorMessage = `${e.response.data.msg}`;
          } else if (e.response.status === 500) {
            errorMessage = "Internal Server Error, please contact Admin";
          } else {
            errorMessage = `${e}`;
          }
          loader.loading(false, "");
          setDisableUI((_) => true);
          setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
          // set customer profile information
          setCustomerProfile((_) => {
            return {
              first_name: "",
              last_name: "",
              mobile_number: "",
              email: "",
            };
          });
        });
      return;
    }
    // otherwise redirect to login page
    window.location.href = "/sign-in";
  }, []);

  // verify everythong
  const verifyAndPopulate = () => {
    //
    if (firstNameRef.current.value) {
      lastNameRef.current.value = lastNameRef.current.value
        ? lastNameRef.current.value
        : customerProfile.last_name;

      phoneNumberRef.current.value = phoneNumberRef.current.value
        ? phoneNumberRef.current.value
        : customerProfile.mobile_number;
    } else if (lastNameRef.current.value) {
      firstNameRef.current.value = firstNameRef.current.value
        ? firstNameRef.current.value
        : customerProfile.first_name;
      phoneNumberRef.current.value = phoneNumberRef.current.value
        ? phoneNumberRef.current.value
        : customerProfile.mobile_number;
    } else if (phoneNumberRef.current.value) {
      firstNameRef.current.value = firstNameRef.current.value
        ? firstNameRef.current.value
        : customerProfile.first_name;

      lastNameRef.current.value = lastNameRef.current.value
        ? lastNameRef.current.value
        : customerProfile.last_name;
    }
  };

  // useeffect
  React.useEffect(() => {
    getCustomerProfile();
  }, [getCustomerProfile]);

  //
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            border: "none",
            backgroundColor: "var(--primary-color)",
            padding: "1rem 0",
          }}
        >
          <div className="h5" style={{ fontWeight: "800" }}>
            Profile
          </div>
        </Card.Header>
        {/*form validation*/}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Card.Body className="px-0">
            {/*notification*/}
            {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
            {/*notification*/}

            {/*email*/}
            <Form.Group className="mb-3">
              <Form.Label>Enter email</Form.Label>
              <Form.Control
                className="fw-bold"
                ref={emailRef}
                required={false}
                disabled={true}
                placeholder={`${customerProfile.email}`}
                type="email"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Text>Contact admin to change your email</Form.Text>
            </Form.Group>
            {/*email*/}
            {/*First Name*/}
            <Form.Group className="mb-3">
              <Form.Label>First name</Form.Label>
              <Form.Control
                className="fw-bold"
                required={true}
                ref={firstNameRef}
                disabled={disableUI}
                placeholder={`Name: ${customerProfile.first_name}`}
                type="text"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid first name
              </Form.Control.Feedback>
            </Form.Group>
            {/*First Name*/}
            {/*last name*/}
            <Form.Group className="mb-3">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                className="fw-bold"
                required={true}
                ref={lastNameRef}
                disabled={disableUI}
                placeholder={`Surname: ${customerProfile.last_name}`}
                type="text"
              />

              <Form.Control.Feedback type="invalid">
                Please provide a valid last name
              </Form.Control.Feedback>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            {/*last name*/}
            {/*phone number*/}
            <Form.Group>
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                className="fw-bold"
                required={true}
                ref={phoneNumberRef}
                disabled={disableUI}
                placeholder={`Phone #no: ${customerProfile.mobile_number}`}
                maxLength={10}
                minLength={10}
                type="tel"
              />

              <Form.Control.Feedback type="invalid">
                Please provide a valid phone number
              </Form.Control.Feedback>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Text>Insert valid phone number</Form.Text>
            </Form.Group>
            {/*phone number*/}

            {/*form validation*/}
          </Card.Body>
          <Card.Footer
            style={{
              border: "none",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              backgroundColor: "var(--primary-color-card)",
            }}
          >
            <Button
              disabled={disableUI}
              className="btn-sm"
              variant="secondary"
              type="submit"
            >
              UPDATE
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
}
