import React from "react";
import { Button, Card } from "react-bootstrap";
import { QuoteInvoice } from "./quote-invoice";

import { LOADER_CONTEXT } from "../../utils/contexts";
import { isLoggedin } from "../../utils/isloggedin";
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";
import api from "../../utils/api";
import { GetTokens } from "../../utils/retrieve_tokens";
import { TIMEOUT } from "../../utils/timeout";
import { Link } from "react-router-dom";

export function BookingCard({ payload }) {
  //notification
  const [notificationType, setNotificationType] = new React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = new React.useState("");
  const [trailingMessage, setTrailingMessage] = new React.useState("");

  // loader
  const loader = React.useContext(LOADER_CONTEXT);

  // function to cancel booking
  const cancelBooking = async () => {
    // logged in
    if (isLoggedin()) {
      // load
      loader.loading(true, "Cancelling ...");
      setNotification(NOTIFICATION_TYPE.none, "", "");
      // retrieve access token
      const token = GetTokens();
      // config
      // config
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      };

      // url
      const url = `bookings/update-booking/${payload.id}/`;
      await api
        .put(
          url,
          {
            booking_cancelled: true,
            routes: payload.locations,
            pk: payload.id,
          },
          config
        )
        .then(async (r) => {
          //check status
          if (r.status === 200) {
            loader.loading(false, "");
            setNotification(NOTIFICATION_TYPE.success, "Yey!", "canceled");

            //await
            await TIMEOUT(3000);
            window.location.href = "/bookings";
          }
        })
        .catch((e) => {
          let errorMessage = "";
          if (e.response.status === 401) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 404) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 400) {
            errorMessage = `${e.response.data.msg}`;
          } else if (e.response.status === 500) {
            errorMessage = "Internal Server Error, please contact Admin";
          }
          loader.loading(false, "");
          setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
        });
      //
      return;
    }
  };

  // generate Jobs
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  //show booking canceled
  const bookingCancelled = (isCancelled) => {
    // check
    if (isCancelled) {
      return (
        <div
          style={{
            padding: "0.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="h5 text-danger p-0 m-0">CANCELLED</div>
        </div>
      );
    }
    return;
  };
  //
  return (
    <Card className="border-0">
      <Card.Header
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          border: "none",
          backgroundColor: "var(--primary-color)",
          padding: "1rem 0",
        }}
      >
        <div className="h5">INVOICE {payload.id}</div>
      </Card.Header>
      <Card.Body className="px-0">
        {/*notification*/}
        {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
        {/*notification*/}
        {/*JOB CANCELLED*/}
        {bookingCancelled(payload.booking_cancelled)}
        {/*JOB CANCELLED*/}
        {/*job entert*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="p text-muted fw-bold">DATE</div>
          <div className="p">{payload.pickup_date}</div>
        </div>
        {/*job entert*/}
        {/*job entert*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="p text-muted fw-bold">TIME</div>
          <div className="p">{payload.pickup_time}</div>
        </div>
        {/*job entert*/}
        {/*job entert*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="p text-muted fw-bold">PAYMENT OPTION</div>
          <div className="p">{payload.payment_option}</div>
        </div>
        {/*job entert*/}
        {/*job entert*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="p text-muted fw-bold">VEHICLE TYPE</div>
          <div className="p">{payload.vehicle_type}</div>
        </div>
        {/*job entert*/}
        {/*job entert*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="p text-muted fw-bold">HELPERS</div>
          <div className="p">{payload.additional_helpers}</div>
        </div>
        {/*job entert*/}
        {/*job entert*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="p text-muted fw-bold">FLOORS</div>
          <div className="p">{payload.carry_floor}</div>
        </div>
        {/*job entert*/}
        {/*job entert*/}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingBottom: "1rem",
          }}
        >
          <div className="p text-muted fw-bold">DRIVER NOTE:</div>
          <div className="p">{payload.drivers_note}</div>
        </div>
        {/*job entert*/}
        <QuoteInvoice
          payload={{
            base_quote_price: payload.quote_price,
            mid_month_discount: payload.mid_month_discount,
            generate_quote_price:
              payload.quote_price - payload.mid_month_discount,
          }}
        />
      </Card.Body>
      <Card.Footer
        style={{
          border: "none",
          backgroundColor: "var(--primary-color-card)",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "0.6rem",
        }}
      >
        <Link
          className="btn btn-sm btn-secondary"
          to={`/bookings/booking/${payload.id}/update`}
          reloadDocument={false}
        >
          UPDATE
        </Link>
        <Button
          className="btn-sm"
          variant="danger"
          disabled={payload.booking_cancelled}
          onClick={() => {
            cancelBooking();
          }}
        >
          CANCEL
        </Button>
      </Card.Footer>
    </Card>
  );
}
