import React from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { GOOGLE_API_KEY, STEPS } from "../../../utils/strings";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { HiLocationMarker } from "react-icons/hi";
import { BsXLg } from "react-icons/bs";

// context
import { CONTINUE_CONTEXT } from "../../../utils/contexts";

//import api
import api from "../../../utils/api";

import { NOTIFICATION } from "../../../utils/notification";
import { NOTIFICATION_TYPE } from "../../../utils/strings";
//
export function ChooseLocation() {
  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");
  const [generatingDistance, setGeneratingDistance] = React.useState(false);
  // locations
  const [locs, setLocs] = React.useState([]);
  const [pickupDropoff, setPickupDropOff] = React.useState(
    "Enter Pickup Location"
  );

  // refs
  const searchRef = React.useRef();

  // context
  const context = React.useContext(CONTINUE_CONTEXT);

  //
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_API_KEY,
    debounce: 100,
    options: {
      componentRestrictions: { country: "za" },
    },
  });

  const setPickupDropOffText = (locationList) => {
    //
    if (locationList.length >= 1) {
      setPickupDropOff((_) => "Enter Pickup or DropOff Location");
      return;
    }
    setPickupDropOff((_) => "Enter Pickup Location");
    return;
  };

  const generatingD = (isGenerating) => {
    if (isGenerating) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
    return;
  };

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  // function to determin
  const isPickupDropOff = (list, current) => {
    if (current === 0) {
      return `${current + 1}. Pickup`;
    } else if (current > 0 && current < list.length - 1) {
      return `${current + 1}. Pickup|Dropoff`;
    } else {
      return `${current + 1}. Dropoff`;
    }
  };

  // load existing

  const checkAvailable = () => {
    try {
      const locations = JSON.parse(localStorage.getItem("locations"));
      // check
      if (locations) {
        return [true, locations];
      }
      return [false, {}];
    } catch (error) {
      return [false, {}];
    }
  };

  // load
  const loadLocations = () => {
    // first check loaded
    const [verify, locations] = checkAvailable();
    // verify
    if (verify) {
      setLocs((_) => [...locations.routes]);
      return;
    }
    //
    setLocs((_) => []);
    return;
  };

  React.useEffect(() => {
    loadLocations();
  }, []);
  // function to determine the distance
  const generateDistance = async () => {
    // generate distance
    //bookings/generate-quote-price/
    setGeneratingDistance((_) => true);

    /// TO BE REMOVED
    // const distance = 30;
    // // setpayload
    // const pLoad = {
    //   distance: distance,
    //   routes: locs,
    // };

    // localStorage.setItem("locations", JSON.stringify(pLoad));
    // setGeneratingDistance((_) => false);
    // context.onContinue(STEPS.location);
    // return;
    /// TO BE REMOVED

    // header
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // header
    const url = "bookings/distance-calculator/";
    await api
      .post(url, { routes: locs }, config)
      .then((r) => {
        // check status
        if (r.status === 200) {
          //
          const distance = r.data.distance;
          // setpayload
          const pLoad = {
            distance: distance,
            routes: locs,
          };

          localStorage.setItem("locations", JSON.stringify(pLoad));
          setGeneratingDistance((_) => false);

          // navigate to the next Screen
          context.onContinue(STEPS.location);
        }
      })
      .catch((e) => {
        let errorMessage = "";
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.msg}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        } else {
          errorMessage = `${e}`;
        }
        setGeneratingDistance((_) => false);
        setNotification(NOTIFICATION_TYPE.error, "Opps", `${errorMessage}`);
      });
  };
  // get full details
  const getFullAddress = (place) => {
    placesService?.getDetails(
      {
        placeId: place.place_id,
      },
      (placeDetails) => {
        const loc = {
          primary_text: place.structured_formatting.main_text,
          secondary_text: place.structured_formatting.secondary_text,
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng(),
        };
        setLocs((_) => [...locs, loc]);
        getPlacePredictions({ input: "" });
        searchRef.current.value = "";
      }
    );
  };

  // determine
  const suggestions = (isLoading) => {
    if (isLoading) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
    // otherwise we good
    else if (placePredictions.length <= 0) {
      return;
    } else if (placePredictions.length > 0) {
      return placePredictions.map((v, i) => {
        return (
          <li
            key={i}
            className="btn btn-light w-100 border-0 rounded-0"
            onClick={() => {
              getFullAddress(v);
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                textAlign: "start",
                gap: "0.5rem",
                fontWeight: "500",
              }}
            >
              <div>
                <HiLocationMarker color="black" />
              </div>
              <div className="p">
                {v.structured_formatting.main_text}{" "}
                <span
                  style={{
                    fontWeight: "300",
                  }}
                >
                  {v.structured_formatting.secondary_text}
                </span>
              </div>
            </div>
          </li>
        );
      });
    }
  };
  // show selected
  const showSelected = () => {
    return locs.map((v, i) => {
      return (
        <li
          className="card border-0"
          style={{
            backgroundColor: "var(--primary-color-card)",
          }}
          key={i}
        >
          <div className="card-body">
            <div className="card-title pb-0 mb-0">
              {isPickupDropOff(locs, i)}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                }}
              >
                {v.primary_text}{" "}
                <span
                  style={{
                    fontWeight: "300",
                  }}
                >
                  {v.secondary_text}
                </span>
              </div>
              <div>
                <BsXLg
                  color="red"
                  onClick={() => {
                    const newArray = locs.filter(function (item) {
                      return item !== v;
                    });
                    setLocs((_) => [...newArray]);
                  }}
                />
              </div>
            </div>
          </div>
        </li>
      );
    });
  };

  // enter location
  const scrollToLabel = () => {
    //
    const scrollHeight = document.getElementById("GETQUOTE");
    const label = document.getElementById("ENTERLOCATION");
    window.scrollTo(0, scrollHeight.offsetTop + label.offsetTop - 10);
  };

  ///
  React.useEffect(() => {
    scrollToLabel();
  }, []);

  React.useEffect(() => {
    setPickupDropOffText(locs);
  }, [locs]);
  //
  return (
    <Card className="border-0">
      <Card.Header
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          border: "none",
          backgroundColor: "var(--primary-color)",
        }}
      >
        <div>Step 2 of 5</div>
        <div className="h5">Choose Locations</div>
      </Card.Header>
      <Card.Body className="px-0">
        {/*notification*/}
        {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
        {/*notification*/}
        <Form.Group>
          <Form.Label id="ENTERLOCATION">Enter Location</Form.Label>
          <InputGroup>
            <Form.Control
              className="fw-bold"
              placeholder={`${pickupDropoff}`}
              ref={searchRef}
              onChange={(e) => {
                getPlacePredictions({ input: e.target.value });
              }}
              style={{
                position: "relative",
              }}
            />
            <InputGroup.Text className="bg-white">
              <HiLocationMarker color="red" />
            </InputGroup.Text>
          </InputGroup>
          <ul
            className="shadow-sm"
            style={{
              width: "100%",
              position: "absolute",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              listStyle: "none",
              padding: "0",
              zIndex: "999",
              gap: "0.125rem",
            }}
          >
            {suggestions(isPlacePredictionsLoading)}
          </ul>
        </Form.Group>

        <Card.Text className="mt-2">Selected: </Card.Text>
        {/**/}
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            display: "flex",
            flexDirection: "column",
            gap: "0.675rem",
          }}
        >
          {showSelected()}
        </ul>
        {generatingD(generatingDistance)}
        {/**/}
      </Card.Body>
      <Card.Footer
        style={{
          border: "none",
          backgroundColor: "var(--primary-color-card)",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "0.6rem",
        }}
      >
        <Button
          disabled={locs.length < 2}
          className="btn-sm"
          variant="secondary"
          onClick={() => {
            //generate distance
            generateDistance();
          }}
        >
          CONTINUE
        </Button>
        <Button
          className="btn-sm"
          variant="primary"
          onClick={() => context.onPrevious(STEPS.location)}
        >
          BACK
        </Button>
      </Card.Footer>
    </Card>
  );
}
