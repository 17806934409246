import React from "react";
import { coverTextTitle, coverTextSubTitle } from "../../utils/strings";
import { Row, Col } from "react-bootstrap";

// import cover image
import CoverImage from "../../imgs/cover.svg";

// return
export function CoverPage() {
  // return
  return (
    <div
      style={{
        padding: "1rem",
        backgroundColor: "var(--primary-color-card)",
      }}
    >
      <div className="cover">
        <Row className="g-3" xs={1} sm={1} md={2}>
          <Col
            xs={{
              order: "last",
            }}
            md={{
              order: "first",
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="h1 fw-bold">{coverTextTitle}</div>
            <div className="p">{coverTextSubTitle}</div>
          </Col>
          <Col
            md={{
              order: "first",
            }}
          >
            <img className="w-100 h-100" src={CoverImage} alt="cover img" />
          </Col>
        </Row>
      </div>
    </div>
  );
}
