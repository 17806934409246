import React from "react";
import { Button, Card, Container, Form, InputGroup } from "react-bootstrap";
import jwt_decode from "jwt-decode";

// context
import { LOADER_CONTEXT } from "../../utils/contexts";
import { BOOKING_CONTEXT } from "../../utils/contexts";
//notification
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";

import { TIMEOUT } from "../../utils/timeout";
import api from "../../utils/api";
import { Link } from "react-router-dom";
import { BOOKING_MODAL } from "./modals";
import { isLoggedin } from "../../utils/isloggedin";
import { GetTokens } from "../../utils/retrieve_tokens";

//
export function SigninPage() {
  // ref
  const emailRef = React.useRef();
  const passwordRef = React.useRef();
  // refs
  const [isVisible, setIsVisible] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [disableUI, setDisableUI] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false);
  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");

  // contexts
  const loading = React.useContext(LOADER_CONTEXT);

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  const handleSubmit = async (event) => {
    //
    window.scrollTo(0, 0);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // once everything is cools
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    // set payload
    const payload = {
      email: `${emailRef.current.value}`.toLowerCase(),
      password: passwordRef.current.value,
    };
    await SIGNIN_USER(payload);
  };

  // determine customer name
  const determineCustomerName = () => {
    if (isLoggedin()) {
      // we need to get the token
      const token = GetTokens();
      // decode token
      const decodedToken = jwt_decode(token.access);
      // convert na
      return `${decodedToken.first_name}`.toUpperCase();
    }
    return "";
  };
  //
  const SIGNIN_USER = async (payload) => {
    //
    loading.loading(true, "Signin in ...");
    setDisableUI((_) => true);
    setNotification(NOTIFICATION_TYPE.none, "", "");
    // header
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //
    const url = "auth/api/token/";
    await api
      .post(url, payload, config)
      .then(async (r) => {
        // check
        if (r.status === 200) {
          // save the tokens
          const tokens = {
            access: r.data.access,
            refresh: r.data.refresh,
          };
          //
          localStorage.setItem("tokens", JSON.stringify(tokens));

          window.scrollTo(0, 0);
          loading.loading(false, "");
          setDisableUI((_) => true);
          setNotification(
            NOTIFICATION_TYPE.success,
            "Yey!",
            `Success Welcome ${determineCustomerName()}`
          );

          //check if there a booking
          const [verify, _] = checkExistingBooking();
          if (verify) {
            setShowModal((_) => true);
            return;
          }
          //
          await TIMEOUT(3000);
          window.location.href = "/";
          return;
        }
      })
      .catch((e) => {
        let errorMessage = "";
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.msg}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        } else {
          errorMessage = `${e}`;
        }

        window.scrollTo(0, 0);
        setDisableUI((_) => false);
        loading.loading(false, "");
        setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
      });
    //
  };
  // retrieve existing job
  const checkExistingBooking = () => {
    try {
      const bQuote = JSON.parse(localStorage.getItem("booking-quote"));
      if (bQuote) {
        return [true, bQuote];
      }
      return [false, {}];
    } catch (error) {
      return [false, {}];
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            border: "none",
            backgroundColor: "var(--primary-color)",
            padding: "1rem 0",
          }}
        >
          <div className="h5" style={{ fontWeight: "800" }}>
            {" "}
            SIGNIN
          </div>
        </Card.Header>
        <Card.Body className="px-0">
          {/*notification*/}
          {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
          {/*notification*/}
          {/*form validation*/}
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/*email*/}
            <Form.Group className="mb-3">
              <Form.Label>Enter Email</Form.Label>
              <Form.Control
                className="fw-bold"
                ref={emailRef}
                required={true}
                disabled={disableUI}
                placeholder="unitendlela@example.co.za"
                type="email"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Text>Your email is safe and sound with us</Form.Text>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Email Address
              </Form.Control.Feedback>
            </Form.Group>
            {/*email*/}
            {/*password*/}
            <Form.Group>
              <Form.Label className="text-start">Enter Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  className="fw-bold"
                  ref={passwordRef}
                  required={true}
                  disabled={disableUI}
                  placeholder="Please enter your password"
                  type={isVisible ? "text" : "password"}
                />
                <Button
                  variant="outline-secondary"
                  disabled={disableUI}
                  onClick={() => {
                    setIsVisible((_) => !isVisible);
                  }}
                >
                  {isVisible ? "hide" : "show"}
                </Button>
              </InputGroup>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            {/*password*/}
            {/*Forgot passwords*/}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                fontWeight: "600",
              }}
            >
              <Link
                className="nav__links"
                to="/account-recovery"
                reloadDocument={true}
              >
                Forgot Password?
              </Link>
            </div>
            {/*Forgot passwords*/}
            {/*SIgnin BUtton*/}
            <Button
              variant="secondary"
              className="btn-sm w-100 my-3"
              type="submit"
              disabled={disableUI}
            >
              SIGN IN
            </Button>
            {/*SIgnin BUtton*/}
            {/*Forgot passwords*/}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div>
                Dont have an account?{" "}
                <Link
                  className="nav__links"
                  to="/sign-up"
                  reloadDocument={true}
                >
                  Sign Up
                </Link>
              </div>
            </div>
            {/*Forgot passwords*/}
          </Form>
          {/*form validation*/}
          {/*context*/}
          <BOOKING_CONTEXT.Provider
            value={{
              show: showModal,
              setShow: setShowModal,
            }}
          >
            <BOOKING_MODAL />
          </BOOKING_CONTEXT.Provider>
          {/*context*/}
        </Card.Body>
      </Card>
    </Container>
  );
}
