import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { NOTIFICATION_TYPE, STEPS } from "../../../utils/strings";
import { NOTIFICATION } from "../../../utils/notification";
import { CONTINUE_CONTEXT } from "../../../utils/contexts";

import "./update_date.css";

// react
export function UpdateDateTime() {
  // use context
  const context = React.useContext(CONTINUE_CONTEXT);

  //
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");
  const [startDate, setStartDate] = React.useState(
    new Date(`${context.booking.pickup_date}`)
  );
  const [startTime, setStartTime] = React.useState(
    new Date(`${context.booking.pickup_date} ${context.booking.pickup_time}`)
  );

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };
  // determine correct time
  const correctDateTime = (date, time) => {
    // expand
    const dateString = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    const timeString = `${time.getHours()}:${time.getMinutes()}`;
    const dateTimeString = `${dateString} ${timeString}`;

    // new date
    const newDate = new Date(dateTimeString);

    // get the difference
    const difference = newDate - Date.now() < 5400000;

    // check
    if (difference) {
      // this means
      const LMessage = "Oops!";
      const TMessage = "Time of booking must be atleast 1hr 30min prior.";
      setNotification(NOTIFICATION_TYPE.error, LMessage, TMessage);
      return;
    }
    // save
    localStorage.setItem(
      "update-date-time",
      JSON.stringify({
        pickup_date: dateString,
        pickup_time: timeString,
      })
    );
    setNotification(NOTIFICATION_TYPE.none, "", "");
    context.onContinue(STEPS.datetime);
    return;
  };
  //
  return (
    <Card className="dynamic_card border-0">
      <Card.Header
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          border: "none",
          backgroundColor: "var(--primary-color)",
        }}
      >
        <div>Step 3 of 5</div>
        <div className="h5">Please Choose Date and Time</div>
      </Card.Header>
      <Card.Body className="px-0">
        {/*notification*/}
        {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
        {/*notification*/}
        <Form.Group>
          <Form.Label>Choose Date</Form.Label>
          <DatePicker
            startDate={startDate}
            minDate={Date.now()}
            className="form-control"
            dateFormat="yyyy-MMM-dd"
            selected={startDate}
            showPopperArrow={false}
            onFocus={(e) => (e.target.readOnly = true)}
            onChange={(date) => {
              setStartDate((_) => date);
            }}
          />
        </Form.Group>
        <Form.Group className="my-3">
          <Form.Label>Choose Time</Form.Label>
          <DatePicker
            className="form-control"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            showTimeSelect={true}
            showTimeSelectOnly
            selected={startTime}
            showPopperArrow={false}
            onFocus={(e) => (e.target.readOnly = true)}
            onChange={(dt) => {
              setStartTime(dt);
            }}
          />
        </Form.Group>
      </Card.Body>
      <Card.Footer
        style={{
          border: "none",
          backgroundColor: "var(--primary-color-card)",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "0.6rem",
        }}
      >
        <Button
          // disabled={locs.length < 1}
          className="btn-sm"
          variant="secondary"
          onClick={() => {
            //generate distance
            correctDateTime(startDate, startTime);
          }}
        >
          CONTINUE
        </Button>
        <Button
          className="btn-sm"
          variant="primary"
          onClick={() => context.onPrevious(STEPS.datetime)}
        >
          BACK
        </Button>
      </Card.Footer>
    </Card>
  );
}
