import React from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";
import { LOADER_CONTEXT } from "../../utils/contexts";
import { TIMEOUT } from "../../utils/timeout";
import api from "../../utils/api";

// icons
import { FaStar } from "react-icons/fa";
//
export function FeedBack() {
  // useRef
  const websiteFriendlinessRef = React.useRef();
  const serviceQualityRef = React.useRef();
  const hearAboutUsRef = React.useRef();
  const customerNameRef = React.useRef();

  const [validated, setValidated] = React.useState(false);
  const [disableUI, setDisableUI] = React.useState(false);

  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");

  // ratings
  const [websiteRating, setWebsiteRating] = React.useState(1);
  const [serviceRating, setServiceRating] = React.useState(1);
  // contexts
  const loader = React.useContext(LOADER_CONTEXT);

  // stars
  const stars = Array(5).fill(0);

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };
  //
  const handleSubmit = async (event) => {
    //
    window.scrollTo(0, 0);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // once everything is cools
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    // set payload
    const payload = {
      customer_name: customerNameRef.current.value,
      website_comment: websiteFriendlinessRef.current.value,
      website_rating: websiteRating,
      quality_service_comment: serviceQualityRef.current.value,
      quality_service_rating: serviceRating,
      hear_about_us: hearAboutUsRef.current.value,
    };

    await sendFeedBack(payload);
  };

  // send feed back
  const sendFeedBack = async (payload) => {
    //feedback/
    setDisableUI((_) => true);
    loader.loading(true, "Sending Feed Back ...");
    setNotification(NOTIFICATION_TYPE.none, "", "");
    // config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //
    const url = "feedback/";
    await api
      .post(url, payload, config)
      .then(async (r) => {
        if (r.status === 201) {
          window.scrollTo(0, 0);
          setDisableUI((_) => true);
          loader.loading(false, "");
          setNotification(
            NOTIFICATION_TYPE.success,
            "Yey!. ",
            "successfully send feed back"
          );

          // here we move to another page
          await TIMEOUT(3000);
          window.location.href = "/"; // home page
        }
      })
      .catch((e) => {
        let errorMessage = "";
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.msg}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        } else {
          errorMessage = `${e}`;
        }
        window.scrollTo(0, 0);
        setDisableUI((_) => false);
        loader.loading(false, "");
        setNotification(
          NOTIFICATION_TYPE.error,
          "Oops! error.",
          `${errorMessage}`
        );
      });
  };

  //
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Body className="px-0">
          {/*notification*/}
          {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
          {/*notification*/}
          <Card.Title className="text-center fw-bold">Dear Customer</Card.Title>
          <Card.Subtitle className="text-center fw-normal mb-4">
            For quality control purposes, kindly rate our service:
          </Card.Subtitle>

          {/*form*/}
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/*Customer Name*/}
            <Form.Group className="mb-3">
              <Form.Label>
                <span className="fw-bold">1.</span> Customer Name?
              </Form.Label>
              <Form.Control
                ref={customerNameRef}
                disabled={disableUI}
                placeholder="Optional"
                type="text"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Customer Name
              </Form.Control.Feedback>
            </Form.Group>
            {/*website use
          
            {/*website userfriendliness*/}
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                <span className="fw-bold">2.</span> Website user-friendliness?
              </Form.Label>

              {/*Rating stars*/}
              <div
                style={{
                  padding: "0.875rem 0",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {stars.map((_, i) => {
                  return (
                    <FaStar
                      key={i}
                      style={{
                        cursor: "pointer",
                      }}
                      color={websiteRating > i ? "yellow" : "grey"}
                      onClick={() => setWebsiteRating((_) => i + 1)}
                    />
                  );
                })}
              </div>
              {/*Rating stars*/}
              <Form.Control
                ref={websiteFriendlinessRef}
                required={true}
                disabled={disableUI}
                placeholder="comment"
                type="text"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Email Address
              </Form.Control.Feedback>
            </Form.Group>
            {/*website userfriendliness*/}
            {/*service quality*/}
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                <span className="fw-bold">3.</span> Service quality?
              </Form.Label>
              {/*Rating stars*/}
              <div
                style={{
                  padding: "0.875rem 0",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {stars.map((_, i) => {
                  return (
                    <FaStar
                      key={i}
                      style={{
                        cursor: "pointer",
                      }}
                      color={serviceRating > i ? "yellow" : "grey"}
                      onClick={() => setServiceRating((_) => i + 1)}
                    />
                  );
                })}
              </div>
              {/*Rating stars*/}
              <Form.Control
                ref={serviceQualityRef}
                required={true}
                disabled={disableUI}
                placeholder="comment"
                type="text"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Email Address
              </Form.Control.Feedback>
            </Form.Group>
            {/*service quality*/}
            {/*select*/}
            <Form.Group>
              <Form.Label>
                <span className="fw-bold">4.</span> Where did you hear about us?
              </Form.Label>
              <Form.Select disabled={disableUI} ref={hearAboutUsRef}>
                <option value="Gumtree">Gumtree</option>
                <option value="Facebook">Facebook</option>
                <option value="Referral">Referral</option>
                <option value="Car magnetic stickers">
                  Car magnetic stickers
                </option>
                <option value="Other">Other</option>
              </Form.Select>
              <Form.Text>
                Please kindly select from the dropdown list.
              </Form.Text>
            </Form.Group>
            {/*select*/}

            <div
              className="w-100 mt-3"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                disabled={disableUI}
                variant="secondary"
                className="btn-sm"
                type="submit"
              >
                SEND
              </Button>
            </div>
          </Form>
          {/*form*/}
        </Card.Body>
      </Card>
    </Container>
  );
}
