// check if use is logeed in

export function isLoggedin() {
  // check
  try {
    const _token = localStorage.getItem("tokens");
    if (_token) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}
