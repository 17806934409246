import React from "react";
import { Button, Card } from "react-bootstrap";

// context
import { CONTINUE_CONTEXT } from "../../../utils/contexts";
import { LOADER_CONTEXT } from "../../../utils/contexts";
import { QuoteInvoice } from "../quote-invoice";

import { NOTIFICATION } from "../../../utils/notification";
import { NOTIFICATION_TYPE, STEPS } from "../../../utils/strings";

import api from "../../../utils/api";
import { isLoggedin } from "../../../utils/isloggedin";
import { GetTokens } from "../../../utils/retrieve_tokens";
import { TIMEOUT } from "../../../utils/timeout";
// get quote
export function Quote() {
  //
  const [quote, setQuote] = React.useState({});
  const [loadedSuccessfully, setLoadedSuccessfully] = React.useState(false);
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");
  const [generatingQuote, setGeneratingQuote] = React.useState(false);
  // context
  const context = React.useContext(CONTINUE_CONTEXT);
  const loader = React.useContext(LOADER_CONTEXT);
  //

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  // create payliad
  const generatePayload = () => {
    // add inform ation
    const addInfo = JSON.parse(localStorage.getItem("add-information"));
    const dateTime = JSON.parse(localStorage.getItem("date-time"));
    const vehicle_type = JSON.parse(localStorage.getItem("vehicle-size"));
    const locations = JSON.parse(localStorage.getItem("locations"));

    //
    const payload = {
      vehicle_type: vehicle_type,
      distance: locations.distance,
      carry_floor: addInfo.carry_floor,
      additional_helpers: addInfo.additional_helpers,
      pickup_date: dateTime.pickup_date,
      apply_fifteen_percent: locations.routes.length > 2,
    };
    // return
    return payload;
  };

  // clear booking
  const clearBooking = () => {
    // clear
    localStorage.removeItem("add-information");
    localStorage.removeItem("date-time");
    localStorage.removeItem("vehicle-size");
    localStorage.removeItem("locations");
  };

  // CraeteBookingPayload
  const createBookingPayload = (q) => {
    //
    const addInfo = JSON.parse(localStorage.getItem("add-information"));
    const dateTime = JSON.parse(localStorage.getItem("date-time"));
    const vehicle_type = JSON.parse(localStorage.getItem("vehicle-size"));
    const locations = JSON.parse(localStorage.getItem("locations"));

    // set payload
    const payload = {
      pickup_date: dateTime.pickup_date,
      pickup_time: dateTime.pickup_time,
      quote_price: q.base_quote_price,
      mid_month_discount: q.mid_month_discount,
      distance: locations.distance,
      payment_option: addInfo.payment_option,
      carry_floor: addInfo.carry_floor,
      vehicle_type: vehicle_type,
      drivers_note: addInfo.drivers_note,
      additional_helpers: addInfo.additional_helpers,
      booking_completed: false,
      booking_cancelled: false,
      routes: locations.routes,
    };

    // return payload
    return payload;
  };

  // create booking
  // this assumes that the customer is logged in
  const createBooking = async (payload) => {
    //
    // check if logged in
    if (isLoggedin()) {
      loader.loading(true, "Booking . . .");
      setNotification(NOTIFICATION_TYPE.none, "", "");
      // retrieve access token
      const token = GetTokens();
      // config
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      };

      //customer/create/
      const url = "bookings/customer/create/";
      await api
        .post(url, payload, config)
        .then(async (r) => {
          if (r.status === 201) {
            loader.loading(false, "");
            setNotification(
              NOTIFICATION_TYPE.success,
              "Yey!",
              "successfully created your booking"
            );

            // timeer
            await TIMEOUT(3000);
            clearBooking(); // clear booking
            window.location.href = "/bookings";
          }
        })
        .catch((e) => {
          let errorMessage = "";
          if (e.response.status === 401) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 404) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 400) {
            errorMessage = `${e.response.data.msg}`;
          } else if (e.response.status === 500) {
            errorMessage = "Internal Server Error, please contact Admin";
          } else {
            errorMessage = `${e}`;
          }
          loader.loading(false, "");
          setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
        });

      // reutrrn
      return;
    }

    // first save payload
    localStorage.setItem("booking-quote", JSON.stringify(payload));
    // notify the customer that they need to login
    setNotification(
      NOTIFICATION_TYPE.info,
      "Hey!",
      "Thanks for making a booking with us, kindly please sign in to continue with your booking"
    );
    await TIMEOUT(4000);
    window.location.href = "/sign-in";
    return;
  };

  //
  const generateQuote = React.useCallback(async (payload) => {
    // generating quote
    setGeneratingQuote((_) => true);
    setLoadedSuccessfully((_) => false);
    setNotification(NOTIFICATION_TYPE.none, "", "");
    // headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //
    const url = "bookings/generate-quote-price/";
    await api
      .post(url, payload, config)
      .then((r) => {
        // check status
        if (r.status === 200) {
          //
          setGeneratingQuote((_) => false);
          setLoadedSuccessfully((_) => true);
          // set
          const q = {
            base_quote_price: r.data.base_quote_price,
            generate_quote_price: r.data.generate_quote_price,
            mid_month_discount: r.data.mid_month_discount,
          };
          // set quite
          setQuote((v) => q);
        }
      })
      .catch((e) => {
        //
        let errorMessage = "";
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.msg}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        } else {
          errorMessage = `${e}`;
        }
        setLoadedSuccessfully((_) => false);
        setGeneratingQuote((_) => false);
        setNotification(NOTIFICATION_TYPE.error, "Opps!", `${errorMessage}`);
      });
  }, []);

  // loading
  const isGeneratingQuote = (generating) => {
    if (generating) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <div className="h5">Loading ... </div>
        </div>
      );
    } else if (loadedSuccessfully && generating === false) {
      return (
        <div>
          <QuoteInvoice payload={quote} />
          <div className="mb-3">
            <span className="fw-bold">NB</span> all payments must be made on
            delivery.
          </div>
        </div>
      );
    }
  };

  // ret
  React.useEffect(() => {
    generateQuote(generatePayload());
  }, [generateQuote]);

  return (
    <Card className="border-0">
      <Card.Header
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          border: "none",
          backgroundColor: "var(--primary-color)",
        }}
      >
        <div>Step 5 of 5</div>
        <div className="h5">Your Quote</div>
      </Card.Header>

      <Card.Body className="p-0">
        {/*notification*/}
        {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
        {/*notification*/}
        {isGeneratingQuote(generatingQuote)}
      </Card.Body>
      <Card.Footer
        style={{
          border: "none",
          backgroundColor: "var(--primary-color-card)",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "0.6rem",
        }}
      >
        <Button
          className="btn-sm"
          variant="secondary"
          onClick={async () => {
            // loader.loading(true, "Booking . . .");
            const payload = createBookingPayload(quote);
            await createBooking(payload);
          }}
        >
          BOOK
        </Button>
        <Button
          className="btn-sm"
          variant="primary"
          onClick={() => {
            context.onPrevious(STEPS.quote);
          }}
        >
          BACK
        </Button>
      </Card.Footer>
    </Card>
  );
}
