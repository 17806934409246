import React from "react";

export function SectionHeader({ title, subTitle, ID }) {
  return (
    <div
      id={`${ID}`}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        padding: "0",
        // gap: "10px",
      }}
    >
      <div className="h3" style={{ fontWeight: "800" }}>
        {title}
      </div>
      <div className="h6 text-primary" style={{ letterSpacing: "0.125rem" }}>
        {subTitle}
      </div>
    </div>
  );
}
