import React from "react";
import { Container } from "react-bootstrap";
// icons
import { FaBars } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";

import api from "../../utils/api";
import { TIMEOUT } from "../../utils/timeout";
import { LOADER_CONTEXT } from "../../utils/contexts";
// logo

// utils
import { isLoggedin } from "../../utils/isloggedin";
import { GetTokens } from "../../utils/retrieve_tokens";
// react
export function Navbar() {
  //loader
  const loader = React.useContext(LOADER_CONTEXT);
  // useref
  const navRef = React.useRef();

  // function
  const showNavBar = () => {
    navRef.current.classList.toggle("responsive-nav");
  };

  // clear everythin
  const clearEverything = () => {
    //
    localStorage.clear();
  };

  // determine customer name
  const determineCustomerName = () => {
    if (isLoggedin()) {
      // we need to get the token
      const token = GetTokens();
      // decode token
      const decodedToken = jwt_decode(token.access);
      // convert na
      return `${decodedToken.first_name}`.toUpperCase();
    }
    return "";
  };

  // signout
  const signoutFunc = async () => {
    // process.env.PUBLIC_URL + "./"
    // /auth/api/token/blacklist/
    if (isLoggedin()) {
      // inita loader
      showNavBar();
      await TIMEOUT(2000);
      loader.loading(true, "Signing out ...");
      // retrieve access token
      const token = GetTokens();
      // config
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      };

      // payload
      const payload = {
        refresh: token.refresh,
        access: token.access,
      };

      // url
      const url = "/auth/api/token/blacklist/";
      await api
        .post(url, payload, config)
        .then(async (r) => {
          console.log(r);
          if (r.status === 200) {
            loader.loading(false, "");
            clearEverything();
            await TIMEOUT(2000);
            window.location.href = "/";
          }
        })
        .catch(async (e) => {
          console.log(e);
          loader.loading(false, "");
          clearEverything();
          await TIMEOUT(2000);
          window.location.href = "/";
        });
    }
  };

  // change nav links
  const chooseNavLinks = () => {
    if (isLoggedin()) {
      return (
        <React.Fragment>
          <NavLink
            className="nav__links"
            reloadDocument={true}
            to="/profile"
            onClick={showNavBar}
          >
            <span className="fst-italic fw-light">welcome</span>{" "}
            {determineCustomerName()}
          </NavLink>
          <NavLink
            className="nav__links"
            reloadDocument={true}
            to="/"
            onClick={showNavBar}
          >
            HOME
          </NavLink>

          <NavLink
            className="nav__links"
            reloadDocument={true}
            to="/bookings"
            onClick={showNavBar}
          >
            BOOKINGS
          </NavLink>
          <NavLink
            className="nav__links"
            reloadDocument={true}
            to="/faq"
            onClick={showNavBar}
          >
            FAQ
          </NavLink>
          <NavLink
            className="btn btn-secondary"
            onClick={async () => await signoutFunc()}
          >
            SIGN OUT
          </NavLink>
        </React.Fragment>
      );
    }
    // otherwuse
    return (
      <React.Fragment>
        <NavLink
          className="nav__links"
          to="/"
          reloadDocument={true}
          onClick={showNavBar}
        >
          HOME
        </NavLink>
        <NavLink
          className="nav__links"
          to="/faq"
          reloadDocument={true}
          onClick={showNavBar}
        >
          FAQ
        </NavLink>
        <NavLink
          className="nav__links"
          to="/sign-in"
          reloadDocument={true}
          onClick={showNavBar}
        >
          SIGN IN
        </NavLink>
        <NavLink
          to="/sign-up"
          className="btn btn-secondary"
          onClick={showNavBar}
        >
          SIGN UP
        </NavLink>
      </React.Fragment>
    );
  };

  // return header\
  return (
    <header
      className="shadow-sm"
      style={{
        padding: "0px 1.5rem",
        position: "sticky",
        top: "0px",
        backgroundColor: "white",
        zIndex: "997",
        width: "100%",
      }}
    >
      <Container className="navbar">
        <img
          style={{
            width: "75px",
          }}
          src="https://storage.googleapis.com/unmovers_media/admin-interface/logo/logo.svg"
          alt="Logo"
        />

        <nav ref={navRef}>
          {chooseNavLinks()}
          <button className="nav-close-btn" onClick={showNavBar}>
            <GrClose size={25} />
          </button>
        </nav>

        <div className="hamburger__section">
          <NavLink to="/profile" reloadDocument={true} className="nav__links">
            {determineCustomerName()}
          </NavLink>
          <FaBars className="nav-btn" size={35} onClick={showNavBar} />
        </div>
      </Container>
    </header>
  );
}
