import React from "react";
import { Button, Card, Carousel, Form } from "react-bootstrap";
import { VEHICLES } from "../../../utils/strings";
import { CONTINUE_CONTEXT } from "../../../utils/contexts";
import { STEPS } from "../../../utils/strings";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
// function
export function ChooseVehicle() {
  //
  // use conext
  const context = React.useContext(CONTINUE_CONTEXT);
  // active index
  const [selectedVehicle, setSelectedVehicle] = React.useState(0);

  // onNext
  const onNext = () => {
    //
    if (selectedVehicle < VEHICLES.length - 1) {
      setSelectedVehicle((_) => selectedVehicle + 1);
    }
  };
  const onPrev = () => {
    if (selectedVehicle > 0) {
      setSelectedVehicle((_) => selectedVehicle - 1);
    }
  };
  // on continue
  const onContinue = () => {
    // save to local storage
    localStorage.setItem("vehicle-size", VEHICLES[selectedVehicle].vsize);
    context.onContinue(STEPS.vehicle_size);
  };

  // check available
  const checkAvailable = () => {
    try {
      const vSize = JSON.parse(localStorage.getItem("vehicle-size"));
      // check
      if (vSize) {
        return [true, vSize];
      }
      return [false, 0];
    } catch (error) {
      return [false, 0];
    }
  };

  // get currently selected if available
  const getCurrentlySelected = () => {
    // check
    const [verify, vSize] = checkAvailable();
    if (verify) {
      // this means we found something
      const indexSelected = VEHICLES.findIndex((v) => v.vsize === vSize);
      setSelectedVehicle((_) => indexSelected);
      return;
    }
    //
    setSelectedVehicle((_) => 0);
    return;
  };

  //
  React.useEffect(() => {
    // const item = document.getElementById("GETQUOTE");
    // window.scrollTo(0, item.offsetTop - 25);
    getCurrentlySelected();
  }, []);

  //
  return (
    <Card className="border-0">
      <Card.Header
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          border: "none",
          backgroundColor: "var(--primary-color)",
        }}
      >
        <div>Step 1 of 5</div>
        <div className="h5">Choose Vehicle</div>
      </Card.Header>
      <Card.Body className="text-center px-0">
        <Carousel
          activeIndex={selectedVehicle}
          controls={false}
          style={{
            position: "relative",
          }}
        >
          {VEHICLES.map((v, i) => {
            return (
              <Carousel.Item key={i}>
                <img className="d-block w-100" src={v.img} alt="" />
                <Card.Subtitle className="fw-bold py-3">
                  Selected Vehicle: {v.caption}
                </Card.Subtitle>
                <Card.Text>
                  <span className="fw-bold"> {`${v.vsize} ton`}</span>{" "}
                  {v.description}
                </Card.Text>
              </Carousel.Item>
            );
          })}
          {/*check*/}
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <IoIosArrowBack
              onClick={onPrev}
              size={30}
              color="var(--secondary-color)"
            />
            <IoIosArrowForward
              onClick={onNext}
              size={30}
              color="var(--secondary-color)"
            />
          </div>
        </Carousel>
        {/*check*/}
        {/*Btns*/}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: "1.5rem 0",
            flexWrap: "wrap",
          }}
        >
          {VEHICLES.map((v, i) => {
            return (
              <Form.Group
                key={i}
                style={{
                  fontSize: "0.875rem",
                }}
              >
                <Form.Check
                  key={i}
                  checked={VEHICLES[selectedVehicle].vsize === v.vsize}
                  onChange={(e) => {}}
                  inline={true}
                  value={v.vsize}
                  label={`${v.caption}`}
                  type={"radio"}
                  name={"vehicle_size"}
                  onClick={(e) => {
                    const currentIndex = VEHICLES.findIndex(
                      (v) => `${v.vsize}` === `${e.currentTarget.value}`
                    );
                    setSelectedVehicle((_) => currentIndex);
                  }}
                />
              </Form.Group>
            );
          })}
        </div>
        {/*Btns*/}
      </Card.Body>
      <Card.Footer
        style={{
          border: "none",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "var(--primary-color-card)",
        }}
      >
        <Button className="btn-sm" variant="secondary" onClick={onContinue}>
          CONTINUE
        </Button>
      </Card.Footer>
    </Card>
  );
}
