import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { ABOUT_US, SCREEN_TYPE } from "../../utils/strings";
import { SectionHeader } from "./section-header";
import { VerticalSpacer } from "./vertical-spacer";
import { DETERMINE_SCREEN_TYPE } from "../../utils/screen-type";
import deliveryTruck from "../../imgs/delivery_truck.svg";

// about us
export function AboutUs() {
  const widget = () => {
    return (
      <Col
        sm={5}
        md={5}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img className="w-100" src={deliveryTruck} alt="" />
      </Col>
    );
  };
  const choose = () => {
    switch (DETERMINE_SCREEN_TYPE()) {
      case SCREEN_TYPE.tablet:
        return widget();
      case SCREEN_TYPE.desktop:
        return widget();
      case SCREEN_TYPE.xl_screen:
        return widget();
      case SCREEN_TYPE.xxl_screen:
        return widget();
      default:
        return;
    }
  };
  //
  return (
    <div>
      <VerticalSpacer height={"2rem"} />
      <Row
        xs={1}
        md={2}
        style={{
          padding: "0 1.5rem",
        }}
      >
        {choose()}
        <Col sm={7} md={7}>
          <div>
            <SectionHeader title={"About Us"} subTitle={"OUR STORY"} />
            <Card className="border-0 w-100">
              <Card.Body className="text-center">
                <Card.Text>{ABOUT_US}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}
