import React from "react";
import { Button, Card, Form } from "react-bootstrap";

// context
import { CONTINUE_CONTEXT } from "../../../utils/contexts";

// icons
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { STEPS } from "../../../utils/strings";

//
export function AddInformation() {
  //contect
  const context = React.useContext(CONTINUE_CONTEXT);
  //add helpers
  const [addHelpers, setAddHelpers] = React.useState(1);
  const [carryFloors, setCarryFloors] = React.useState(0);

  // paymentoption refs
  const cashRef = React.useRef();
  const eftRef = React.useRef();
  const driverNoteRef = React.useRef();

  // save to local storage
  const saveLocal = () => {
    //payment
    const paymentOption = eftRef.current.checked ? "EFT" : "CASH";
    const driverNote = driverNoteRef.current.value;

    // create maps
    const save = {
      payment_option: paymentOption,
      drivers_note: driverNote,
      additional_helpers: addHelpers,
      carry_floor: carryFloors,
    };

    //save
    localStorage.setItem("add-information", JSON.stringify(save));
    //save
    context.onContinue(STEPS.addinformation);
  };

  // increment
  const helperIncrement = () => {
    //
    if (addHelpers < 3) {
      setAddHelpers((_) => addHelpers + 1);
    }
  };

  // decrement
  const helperDecrement = () => {
    if (addHelpers > 1) {
      setAddHelpers((_) => addHelpers - 1);
    }
  };

  // floorsIncremen
  const floorsIncrement = () => {
    if (carryFloors < 10) {
      setCarryFloors((_) => carryFloors + 1);
    }
  };
  const floorsDecrement = () => {
    if (carryFloors > 0) {
      setCarryFloors((_) => carryFloors - 1);
    }
  };

  // check availability
  const availability = () => {
    //
    try {
      const addInfo = JSON.parse(localStorage.getItem("add-information"));
      if (addInfo) {
        return [true, addInfo];
      }
      return [false, {}];
    } catch (error) {
      return [false, {}];
    }
  };

  //
  const setAddInformation = () => {
    // check
    const [verify, addInfo] = availability();
    if (verify) {
      //
      setAddHelpers((_) => addInfo.additional_helpers);
      setCarryFloors((_) => addInfo.carry_floor);
      driverNoteRef.current.value = addInfo.drivers_note;
      cashRef.current.checked = addInfo.payment_option === "CASH";
      eftRef.current.checked = addInfo.payment_option === "EFT";
      return;
    }
  };

  // enter location
  const scrollToLabel = () => {
    //
    const scrollHeight = document.getElementById("GETQUOTE");
    const label = document.getElementById("ADD_HELPER");
    window.scrollTo(0, scrollHeight.offsetTop + label.offsetTop - 10);
  };
  React.useEffect(() => {
    scrollToLabel();
    setAddInformation();
  }, []);

  //
  return (
    <Card className="border-0">
      <Card.Header
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          border: "none",
          backgroundColor: "var(--primary-color)",
        }}
      >
        <div>Step 4 of 5</div>
        <div className="h5">Additional Information</div>
      </Card.Header>
      <Card.Body className="px-0">
        {/*Additional helpers*/}
        <div
          id="ADD_HELPER"
          className="mb-3"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IoIosArrowBack size={30} onClick={helperDecrement} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                fontWeight: "600",
              }}
            >
              additional helpers: {addHelpers}
            </div>
            <div
              className="p text-muted"
              style={{
                fontWeight: "400",
                fontSize: "0.875rem",
              }}
            >
              Number of helpers in addition to the driver
            </div>
          </div>
          <IoIosArrowForward size={30} onClick={helperIncrement} />
        </div>
        {/*Additional helpers*/}

        {/*Stairs To cArry*/}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IoIosArrowBack size={30} onClick={floorsDecrement} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                fontWeight: "600",
              }}
            >
              Stairs to carry: {carryFloors}
            </div>
            <div
              className="p text-muted"
              style={{
                fontWeight: "400",
                fontSize: "0.875rem",
              }}
            >
              Number of floors to carry at pickup and drop off address
            </div>
          </div>
          <IoIosArrowForward size={30} onClick={floorsIncrement} />
        </div>
        {/*Stairs To cArry*/}

        {/*Note*/}
        <Form.Group className="mt-3">
          <Form.Label>Note to driver:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            ref={driverNoteRef}
            className="h-25 fw-bold"
            placeholder="optional"
          />
          <Form.Text>
            Any additional information or special requirements concerning your
            booking
          </Form.Text>
        </Form.Group>
        {/*Note*/}
        {/*Payment Option*/}
        <Form.Group className="mt-3">
          <Form.Label>Payment Options:</Form.Label>
          <Form.Check
            type="radio"
            label="EFT"
            ref={eftRef}
            onChange={(v) => {
              cashRef.current.checked = !v.currentTarget.checked;
            }}
          />
          <Form.Check
            type="radio"
            label="CASH"
            checked={true}
            ref={cashRef}
            onChange={(v) => {
              eftRef.current.checked = !v.currentTarget.checked;
            }}
          />
        </Form.Group>
        {/*Payment Option*/}
      </Card.Body>
      <Card.Footer
        style={{
          border: "none",
          backgroundColor: "var(--primary-color-card)",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "0.6rem",
        }}
      >
        <Button
          className="btn-sm"
          variant="secondary"
          onClick={() => {
            saveLocal();
          }}
        >
          CONTINUE
        </Button>
        <Button
          className="btn-sm"
          variant="primary"
          onClick={() => {
            context.onPrevious(STEPS.addinformation);
          }}
        >
          BACK
        </Button>
      </Card.Footer>
    </Card>
  );
}
