import React from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";
import api from "../../utils/api";
import { LOADER_CONTEXT } from "../../utils/contexts";
import { TIMEOUT } from "../../utils/timeout";
//
export function ResetPassword() {
  //ref
  const passwordRef = React.useRef();
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");
  const [validated, setValidated] = React.useState(false);
  const [disableUI, setDisableUI] = React.useState(false);

  // contexts
  const loading = React.useContext(LOADER_CONTEXT);

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  const handleSubmit = async (event) => {
    //
    window.scrollTo(0, 0);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // once everything is cools
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    await resetPassword(getPayload());
  };

  //set payload
  const getPayload = () => {
    const [verify, six] = check();
    if (verify) {
      const pay = {
        user_id: six.user_id,
        user_email: six.email,
        new_password: passwordRef.current.value,
      };

      return pay;
    }
    return {};
  };
  //
  const check = () => {
    try {
      const six = JSON.parse(localStorage.getItem("six_digit_pin"));
      if (six) {
        return [true, six];
      }
      return [false, {}];
    } catch (error) {
      return [false, {}];
    }
  };

  const resetPassword = async (payload) => {
    /// loader
    setDisableUI((_) => true);
    loading.loading(true, "Reseting Password ...");
    setNotification(NOTIFICATION_TYPE.none, "", "");

    //
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // url
    const url = "auth/api/reset-user-password/";
    await api
      .patch(url, payload, config)
      .then(async (r) => {
        setDisableUI((_) => true);
        loading.loading(false, "");
        setNotification(
          NOTIFICATION_TYPE.success,
          "Yey",
          "Password reset successfully"
        );

        // remove
        localStorage.removeItem("six_digit_pin");
        await TIMEOUT(3000);
        window.location.href = "/sign-in";
      })
      .catch((e) => {
        let errorMessage = "";
        if (e.response.status === 401) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 404) {
          errorMessage = `${e.response.data.detail}`;
        } else if (e.response.status === 400) {
          errorMessage = `${e.response.data.msg}`;
        } else if (e.response.status === 500) {
          errorMessage = "Internal Server Error, please contact Admin";
        } else {
          errorMessage = `${e}`;
        }
        setDisableUI((_) => false);
        loading.loading(false, "");
        setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
      });
  };

  //
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            border: "none",
            backgroundColor: "var(--primary-color)",
            padding: "1rem 0",
          }}
        >
          <div className="h5">Reset Password</div>
        </Card.Header>
        {/*form*/}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Card.Body className="px-0">
            {/*notification*/}
            {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
            {/*notification*/}
            {/*password*/}
            <Form.Group>
              <Form.Label className="text-start">Reset Password</Form.Label>
              <Form.Control
                className="fw-bold"
                ref={passwordRef}
                required={true}
                disabled={disableUI}
                type="password"
                placeholder="Please enter your password"
              />
              <Form.Text>Your password is safe with us.</Form.Text>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Password
              </Form.Control.Feedback>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            {/*password*/}
          </Card.Body>
          <Card.Footer
            style={{
              border: "none",
              backgroundColor: "var(--primary-color-card)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gap: "0.6rem",
            }}
          >
            <Button
              className="btn-sm"
              variant="secondary"
              disabled={disableUI}
              type="submit"
            >
              RESET PASSWORD
            </Button>
          </Card.Footer>
        </Form>
        {/*form*/}
      </Card>
    </Container>
  );
}
