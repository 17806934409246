import React from "react";
import { SectionHeader } from "./section-header";

import { OUR_SERVICES } from "../../utils/strings";
import { Row, Col, Card } from "react-bootstrap";
import { VerticalSpacer } from "./vertical-spacer";
// ... ourservices
export function OurServices() {
  // header
  const title = "Our Services";
  const subTitle = "HERE'S WHAT WE OFFER";
  //
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        padding: "0 1rem",
      }}
    >
      <VerticalSpacer height={"2rem"} />
      <SectionHeader title={title} subTitle={subTitle} />
      <VerticalSpacer height={"rem"} />
      <Row xs={1} md={2} lg={4}>
        {OUR_SERVICES.map((v, i) => {
          return (
            <Col key={i} className="g-2">
              <Card
                className="w-100 h-100 border-0"
                style={{
                  backgroundColor: "var(--primary-color-card)",
                }}
              >
                <Card.Body className="text-center">
                  <img
                    className="w-25 img-fluid p-0 m-0"
                    src={v.img}
                    alt={v.title}
                  />
                  <Card.Title className="my-3 fw-bold">{v.title}</Card.Title>
                  <Card.Text className="p-0 m-0">{v.subTitle}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
