import React from "react";
import { SectionHeader } from "./section-header";
import { STEPS } from "../../utils/strings";
import { ChooseVehicle } from "./create-booking/vehicle_size";
import { ChooseLocation } from "./create-booking/choose_location";
// context
import { CONTINUE_CONTEXT } from "../../utils/contexts";
import { DateTime } from "./create-booking/date_time";
import { AddInformation } from "./create-booking/add-information";
import { Quote } from "./create-booking/quote";
import { VerticalSpacer } from "./vertical-spacer";
// create booking
export function CreateBooking() {
  //
  const [currentStep, setCurrentStep] = React.useState(STEPS.vehicle_size);
  //
  const chooseStep = (currentStep) => {
    switch (currentStep) {
      case STEPS.vehicle_size:
        return <ChooseVehicle />;
      case STEPS.location:
        return <ChooseLocation />;
      case STEPS.datetime:
        return <DateTime />;
      case STEPS.addinformation:
        return <AddInformation />;
      case STEPS.quote:
        return <Quote />;

      default:
        return;
    }
  };

  const onContinue = (currentStep) => {
    switch (currentStep) {
      case STEPS.vehicle_size:
        return setCurrentStep((_) => STEPS.location);
      case STEPS.location:
        return setCurrentStep((_) => STEPS.datetime);
      case STEPS.datetime:
        return setCurrentStep((_) => STEPS.addinformation);
      case STEPS.addinformation:
        return setCurrentStep((_) => STEPS.quote);
      default:
        return;
    }
  };

  const onPrevious = (currentStep) => {
    switch (currentStep) {
      case STEPS.quote:
        return setCurrentStep((_) => STEPS.addinformation);
      case STEPS.addinformation:
        return setCurrentStep((_) => STEPS.datetime);
      case STEPS.datetime:
        return setCurrentStep((_) => STEPS.location);
      case STEPS.location:
        return setCurrentStep((_) => STEPS.vehicle_size);
      default:
        return;
    }
  };

  // return
  return (
    <div
      style={{
        padding: "0 1rem",
      }}
    >
      <VerticalSpacer height={"2rem"} />
      <SectionHeader title={"Get your Quote"} ID={"GETQUOTE"} />
      <VerticalSpacer height={"1rem"} />
      <CONTINUE_CONTEXT.Provider
        value={{ onContinue: onContinue, onPrevious: onPrevious }}
      >
        {chooseStep(currentStep)}
      </CONTINUE_CONTEXT.Provider>
    </div>
  );
}
