import React from "react";
import { Card, Container } from "react-bootstrap";
import { isLoggedin } from "../../utils/isloggedin";
import { GetTokens } from "../../utils/retrieve_tokens";
import { BookingCard } from "../sub-components/booking-card";

import { LOADER_CONTEXT } from "../../utils/contexts";
import api from "../../utils/api";

import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";

// bookings
export function Bookings() {
  //
  const [customerBookings, setCustomerBookings] = React.useState([]);
  const [bookingsLoaded, setBookingsLoaded] = React.useState(false);
  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");

  // generate Jobs
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };
  // context
  const loader = React.useContext(LOADER_CONTEXT);
  const retrieveBookings = React.useCallback(async () => {
    // check if logged in
    if (isLoggedin()) {
      //
      loader.loading(true, "Fetching Bookings ...");
      setBookingsLoaded((_) => false);
      setNotification(NOTIFICATION_TYPE.none, "", "");
      // retrieve access token
      const token = GetTokens();
      // config
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      };

      // url
      const url = "bookings/customer/list/";
      await api
        .get(url, config)
        .then((r) => {
          if (r.status === 200) {
            loader.loading(false, "");
            setBookingsLoaded((_) => true);
            setNotification(NOTIFICATION_TYPE.none, "", "");
            setCustomerBookings((_) => r.data.results);
          }
        })
        .catch((e) => {
          // configure message
          let errorMessage = "";
          if (e.response.status === 401) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 404) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 400) {
            errorMessage = `${e.response.data.msg}`;
          } else if (e.response.status === 500) {
            errorMessage = "Internal Server Error, please contact Admin";
          }
          //
          setBookingsLoaded((_) => false);
          loader.loading(false, "");
          setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
        });
      //
    } else {
    }
  }, []);

  const bLoaded = (loaded) => {
    if (loaded) {
      return customerBookings.map((payload, i) => {
        return (
          <div className="pb-5" key={i}>
            <BookingCard payload={payload} />
          </div>
        );
      });
    }
  };

  React.useEffect(() => {
    retrieveBookings();
  }, [retrieveBookings]);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card className="dynamic_card border-0">
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            color: "#000",
            border: "0",
            padding: "0",
            backgroundColor: "transparent",
          }}
        >
          <div className="h4 py-2" style={{ fontWeight: "800" }}>
            Bookings
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {/*notification*/}
          {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
          {/*notification*/}
          {bLoaded(bookingsLoaded)}
        </Card.Body>
      </Card>
    </Container>
  );
}
