import React from "react";
import { useParams } from "react-router-dom";
import { isLoggedin } from "../../utils/isloggedin";
import { STEPS } from "../../utils/strings";
import { SectionHeader } from "./section-header";
import { UpdateAddInformation } from "./update-booking/update-add-information";
import { UpdatedQuote } from "./update-booking/updated_quote";
import { UpdateChooseLocation } from "./update-booking/update_choose_location";
import { UpdateDateTime } from "./update-booking/update_date_time";
import { UpdateChooseVehicle } from "./update-booking/update_vehicle_size";
import { VerticalSpacer } from "./vertical-spacer";
import { CONTINUE_CONTEXT } from "../../utils/contexts";
import { Container } from "react-bootstrap";

// api
import api from "../../utils/api";

//
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";

//
import { LOADER_CONTEXT } from "../../utils/contexts";
import { GetTokens } from "../../utils/retrieve_tokens";

export function UpdateBooking() {
  //
  const { id } = useParams();
  //
  const loader = React.useContext(LOADER_CONTEXT);
  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");
  const [booking, setBooking] = React.useState({});
  const [success, setSuccess] = React.useState(false);
  //
  const [currentStep, setCurrentStep] = React.useState(STEPS.vehicle_size);

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };
  //
  const chooseStep = (currentStep) => {
    switch (currentStep) {
      case STEPS.vehicle_size:
        return <UpdateChooseVehicle />;
      case STEPS.location:
        return <UpdateChooseLocation />;
      case STEPS.datetime:
        return <UpdateDateTime />;
      case STEPS.addinformation:
        return <UpdateAddInformation />;
      case STEPS.quote:
        return <UpdatedQuote />;
      default:
        return;
    }
  };
  const onContinue = (currentStep) => {
    switch (currentStep) {
      case STEPS.vehicle_size:
        return setCurrentStep((_) => STEPS.location);
      case STEPS.location:
        return setCurrentStep((_) => STEPS.datetime);
      case STEPS.datetime:
        return setCurrentStep((_) => STEPS.addinformation);
      case STEPS.addinformation:
        return setCurrentStep((_) => STEPS.quote);
      default:
        return;
    }
  };

  const onPrevious = (currentStep) => {
    switch (currentStep) {
      case STEPS.quote:
        return setCurrentStep((_) => STEPS.addinformation);
      case STEPS.addinformation:
        return setCurrentStep((_) => STEPS.datetime);
      case STEPS.datetime:
        return setCurrentStep((_) => STEPS.location);
      case STEPS.location:
        return setCurrentStep((_) => STEPS.vehicle_size);
      default:
        return;
    }
  };

  // function to fetch booking
  const fetchBooking = React.useCallback(async () => {
    // check if logged in
    if (isLoggedin()) {
      //
      loader.loading(true, "Loading Booking...");
      setNotification(NOTIFICATION_TYPE.none, "", "");
      setSuccess((_) => false);
      // retrieve access token
      const token = GetTokens();
      // config
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      };

      // const url
      const url = `bookings/retrieve-booking/${id}/`;
      await api
        .get(url, config)
        .then((r) => {
          // check status
          if (r.status === 200) {
            loader.loading(false, "");
            setSuccess((_) => true);
            setNotification(NOTIFICATION_TYPE.none, "", "");
            setBooking((_) => {
              return {
                ...r.data,
              };
            });
          }
        })
        .catch((e) => {
          let errorMessage = "";
          if (e.response.status === 401) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 404) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 400) {
            errorMessage = `${e.response.data.msg}`;
          } else if (e.response.status === 500) {
            errorMessage = "Internal Server Error, please contact Admin";
          }
          loader.loading(false, "");
          setSuccess((_) => false);
          setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
        });
      return;
    }

    // otherwise
    window.location.href = "/sign-in";
    return;
  }, []);

  // successfully loaded
  const LoadedSuccesfully = (loaded) => {
    if (loaded) {
      return (
        <CONTINUE_CONTEXT.Provider
          value={{
            onContinue: onContinue,
            onPrevious: onPrevious,
            booking: booking,
          }}
        >
          {chooseStep(currentStep)}
        </CONTINUE_CONTEXT.Provider>
      );
    }
    return <></>;
  };

  //
  React.useEffect(() => {
    fetchBooking();
  }, [fetchBooking]);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      <SectionHeader
        title={"Update"}
        subTitle={`Booking invoice: ${id}`}
        ID={"UPDATE-ROUTES"}
      />
      <VerticalSpacer height={"1rem"} />
      {/*notification*/}
      {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
      {/*notification*/}
      {LoadedSuccesfully(success)}
    </Container>
  );
}
