import React from "react";
import { WHY_CHOOSE_US } from "../../utils/strings";

import { SectionHeader } from "./section-header";
import { VerticalSpacer } from "./vertical-spacer";

// function
export function WhyChooseUsList() {
  //
  return (
    <div>
      <VerticalSpacer height={"2rem"} />
      <SectionHeader
        title={"Why Choose Us?"}
        subTitle={"WHY OUR SERVICES ARE VALUED"}
      />
      <VerticalSpacer height={"1rem"} />
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "1rem",
          listStyleType: "none",
          padding: "0",
        }}
      >
        {WHY_CHOOSE_US.map((v, i) => {
          return (
            <li className="whychooseus__li" key={i}>
              {v.subTitle}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
