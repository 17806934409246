import React from "react";
import { Container } from "react-bootstrap";
import { AboutUs } from "../sub-components/about-us";
import { CoverPage } from "../sub-components/cover-section";
import { Coverage } from "../sub-components/coverage";
import { Gallery } from "../sub-components/gallery";
import { OurServices } from "../sub-components/our-services";
import { Testimonials } from "../sub-components/testimonials";
import { CreateBookingWhyChooseUsSection } from "../sub-components/createbooking_whychooseus";
import { ResponsiveWhyChooseUs } from "../sub-components/responsive-why-choose-us";
import { ProjectLife } from "../sub-components/project-life";

//
export function MainPage() {
  return (
    <Container className="p-0">
      {/* <ProjectLife /> */}
      <CoverPage /> {/*Fine*/}
      <OurServices /> {/*Fine*/}
      <CreateBookingWhyChooseUsSection /> {/*Error*/}
      <ResponsiveWhyChooseUs /> {/*Fine*/}
      <Testimonials /> {/*Fine*/}
      <AboutUs /> {/*Error*/}
      <Gallery /> {/*Fine*/}
      <Coverage /> {/*Error*/}
    </Container>
  );
}
