import React from "react";
import { Col, Row } from "react-bootstrap";
import { DETERMINE_SCREEN_TYPE } from "../../utils/screen-type";
import { SCREEN_TYPE } from "../../utils/strings";
import { CreateBooking } from "./create-booking";
import { WhyChooseUsList } from "./why-choose-us-list";

export function CreateBookingWhyChooseUsSection() {
  //choose
  const choose = () => {
    switch (DETERMINE_SCREEN_TYPE()) {
      case SCREEN_TYPE.desktop:
        return (
          <Col>
            <WhyChooseUsList />{" "}
          </Col>
        );
      case SCREEN_TYPE.xl_screen:
        return (
          <Col>
            <WhyChooseUsList />{" "}
          </Col>
        );
      case SCREEN_TYPE.xxl_screen:
        return (
          <Col>
            <WhyChooseUsList />{" "}
          </Col>
        );

      default:
        return;
    }
  };

  return (
    <Row>
      <Col>
        <CreateBooking />
      </Col>
      {choose()}
    </Row>
  );
}
