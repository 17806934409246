import React from "react";

//
export function VerticalSpacer({ height }) {
  return (
    <div
      style={{
        height: height,
      }}
    />
  );
}
