import React from "react";
import { DETERMINE_SCREEN_TYPE } from "../../utils/screen-type";
import { SCREEN_TYPE } from "../../utils/strings";
import { WhyChooseUs } from "./why-choose-us";

//
export function ResponsiveWhyChooseUs() {
  //switch
  const determine = () => {
    switch (DETERMINE_SCREEN_TYPE()) {
      case SCREEN_TYPE.mobile:
        return <WhyChooseUs />;
      case SCREEN_TYPE.tablet:
        return <WhyChooseUs />;
      default:
        return;
    }
  };
  //
  return <React.Fragment>{determine()}</React.Fragment>;
}
