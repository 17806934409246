import React from "react";
import { Button, Card, Modal } from "react-bootstrap";

//notification
import { NOTIFICATION } from "../../utils/notification";
import { NOTIFICATION_TYPE } from "../../utils/strings";

import { BOOKING_CONTEXT } from "../../utils/contexts";
import { isLoggedin } from "../../utils/isloggedin";
import { GetTokens } from "../../utils/retrieve_tokens";
import jwt_decode from "jwt-decode";
import { TIMEOUT } from "../../utils/timeout";

import api from "../../utils/api";

// function
export function BOOKING_MODAL() {
  //contex REact
  const [booking, setBooking] = React.useState({});
  const [retrieveBookingSuccess, setRetrieveBookingSuccess] =
    React.useState(false);
  const [disableUI, setDisableUI] = React.useState(false);

  //notification
  const [notificationType, setNotificationType] = React.useState(
    NOTIFICATION_TYPE.none
  );
  const [leadingMessage, setLeadingMessage] = React.useState("");
  const [trailingMessage, setTrailingMessage] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  //context
  const context = React.useContext(BOOKING_CONTEXT);

  const formater = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "zar",
    minimumSignificantDigits: 1,
  });

  // function
  const setNotification = (nCategory, LMessage, TMessage) => {
    setNotificationType((_) => nCategory);
    setLeadingMessage((_) => LMessage);
    setTrailingMessage((_) => TMessage);
  };

  // clear booking
  const clearBooking = () => {
    // clear
    localStorage.removeItem("add-information");
    localStorage.removeItem("date-time");
    localStorage.removeItem("vehicle-size");
    localStorage.removeItem("locations");
    localStorage.removeItem("booking-quote");
  };

  // get customer name
  const determineCustomerName = () => {
    if (isLoggedin()) {
      // we need to get the token
      const token = GetTokens();
      // decode token
      const decodedToken = jwt_decode(token.access);
      // convert na
      return `${decodedToken.first_name}`.toUpperCase();
    }
    return "";
  };

  // retrieve existing job
  const checkExistingBooking = () => {
    try {
      const bQuote = JSON.parse(localStorage.getItem("booking-quote"));
      if (bQuote) {
        return [true, bQuote];
      }
      return [false, {}];
    } catch (error) {
      return [false, {}];
    }
  };
  const retrieveExistingBooking = () => {
    const [verify, bQuote] = checkExistingBooking();
    if (verify) {
      setRetrieveBookingSuccess((_) => true);
      setBooking((_) => bQuote);
      return;
    }
    setRetrieveBookingSuccess((_) => false);
    setBooking((_) => bQuote);
    return;
  };

  // loading
  const isLoading = (loading) => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
  };
  //
  const createBooking = async (payload) => {
    // check logged in
    if (isLoggedin()) {
      setLoading((_) => true);
      setDisableUI((_) => true);
      setNotification(NOTIFICATION_TYPE.none, "", "");

      // retrieve access token
      const token = GetTokens();
      // config
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      };

      //customer/create/
      const url = "bookings/customer/create/";
      await api
        .post(url, payload, config)
        .then(async (r) => {
          setDisableUI((_) => true);
          setLoading((_) => false);
          setNotification(
            NOTIFICATION_TYPE.success,
            "Yey!",
            "successfully created your booking"
          );

          // timeer
          await TIMEOUT(3000);
          clearBooking(); // clear booking
          window.location.href = "/bookings";
        })
        .catch((e) => {
          let errorMessage = "";
          if (e.response.status === 401) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 404) {
            errorMessage = `${e.response.data.detail}`;
          } else if (e.response.status === 400) {
            errorMessage = `${e.response.data.msg}`;
          } else if (e.response.status === 500) {
            errorMessage = "Internal Server Error, please contact Admin";
          } else {
            errorMessage = `${e}`;
          }
          setLoading((_) => false);
          setDisableUI((_) => false);
          setNotification(NOTIFICATION_TYPE.error, "Oops!", `${errorMessage}`);
        });

      // reutrrn
      return;
    }
  };

  React.useEffect(() => {
    retrieveExistingBooking();
  }, []);

  // itme
  const item = (leading, trailing) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="p text-muted">{leading}</div>
        <div className="p fw-bold">{trailing}</div>
      </div>
    );
  };

  // success
  const loadBooking = (loaded) => {
    if (loaded) {
      return (
        <React.Fragment>
          {item("Booking Date", `${booking.pickup_date}`)}
          {item("Booking Time", `${booking.pickup_time}`)}
          {item("Vehicle type", `${booking.vehicle_type} ton`)}
          {item("Additional helpers", `${booking.additional_helpers}`)}
          {item("Floors to carry", `${booking.carry_floor}`)}
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="p fw-bold">Quote</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "6px",
              }}
            >
              <div className="p fw-normal">
                {formater.format(booking.quote_price).replace("ZA", " ")}
              </div>
              <div className="p fw-bold">{booking.payment_option}</div>
            </div>
          </div>
          <hr />
          <div className="p">
            Press PROCEED button to make this booking or DISCARD to cancel this
            booking.
          </div>
        </React.Fragment>
      );
    }
  };

  return (
    <Modal
      show={context.show}
      centered={true}
      className="custom__modal border-0"
    >
      <Modal.Header className="bg-primary border-0 text-white">
        <Modal.Title>Existing Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 border-0">
        <Card className="rounded-0 border-0">
          <Card.Body>
            {/*notification*/}
            {NOTIFICATION(notificationType, leadingMessage, trailingMessage)}
            {/*notification*/}
            {/*loading*/}
            {isLoading(loading)}
            {/*loading*/}
            <Card.Title>Welcome {determineCustomerName()}</Card.Title>
            <Card.Text className="fw-normal p-0">
              We have detected a previously unconfirmed booking, with the
              following invoice details
            </Card.Text>

            {/*loaded*/}
            {loadBooking(retrieveBookingSuccess)}
            {/*loaded*/}
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="btn-sm"
          disabled={disableUI}
          onClick={() => {
            createBooking(booking);
          }}
        >
          PROCEED
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          disabled={disableUI}
          onClick={async () => {
            //
            clearBooking();
            context.setShow((_) => false);
            await TIMEOUT(3000);
            window.location.href = "/";
            return;
          }}
        >
          DISCARD
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
