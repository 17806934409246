import React from "react";
import { ScreenSize } from "./responsive";

// determine the

export function SlidesPerView() {
  // size
  const windowSize = ScreenSize();
  // slides perview
  if (windowSize >= 0 && windowSize < 576) {
    return 1;
  } else if (windowSize >= 576 && windowSize < 768) {
    return 2;
  } else {
    return 3;
  }
}

export function RowsPerView() {
  // size
  const windowSize = ScreenSize();

  // check
  if (windowSize >= 0 && windowSize < 768) {
    return 1;
  } else {
    return 2;
  }
}
